@if $cmp-menu {
  /*------------------------------------*\
  MENU
\*------------------------------------*/
  /**
 * CONTENTS
 * HORIZONTAL..........
 * VERTICAL............
 */
  /*------------------------------------*\
  $HORIZONTAL
\*------------------------------------*/
  .menu--horizontal {
    @media (max-width: ($cmp-menu-mobile-breakpoint - 1)) {
      align-self: flex-start;
      flex: 1;
      .menu__item {
        background-color: $darkblue;
        .menu__link {
          padding: 1em;
          color: #fff;
          border-bottom: 1px solid $lightblue;
          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
        .menu__link--level2 {
          padding-left: 2.5em;
        }
        .menu__link--level1 {
          text-transform: uppercase;
        }
      }
    }
    @media (min-width: $cmp-menu-mobile-breakpoint) {
      display: flex;
      align-items: center;
      justify-content: center;

      .menu__list--level1 {
        align-self: flex-start;
        flex: 1;
        
        @media (max-width: 1025px) {
          display: flex;
          justify-content: space-around;
          margin-top: 2em; 
        }

        .menu__link--level1 {
          font-family: $font-a-family;
          font-size: 1.1em;
        }
        li {
          padding-right: 1.5em;
          a {
            color: $font-base;
          }
          ul {
            width: 250px;
            left: -5em;
            li {
              background-color: $darkblue;
              border-bottom: 1px solid $lightblue;
              transition: 0.1s, background-color;
              a {
                padding: 1em;
                color: #fff;
                &:hover {
                  text-decoration: none;
                }
              }
            }
            li:hover {
              background-color: $lightblue;
            }
            li:last-child {
              border: none;
            }
          }
          ul:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            margin-left: 8em;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $darkblue;
          }
          &.menu__item--1033 ul {
            @media (max-width: 83em) {
              left: 0;
              &:before {
                margin-left: 2.5em;
              }
            }
          }
        }
      }
    }
  }
  /*------------------------------------*\
  $VERTICAL
\*------------------------------------*/
  .menu--vertical {}
} // endif

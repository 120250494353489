#cmp--content {
  margin-top: 0;
}
body {
  margin-top: 10px;
}
.skiplinks, #block--2, #block--3, #cmp--footer, #cmp--header {
  display: none !important;
}

.event-file-btn {
  display: none !important;
}

article.event .grid__item--m--9, .field-event-location {
  display: none;
}
body {
  padding-top: 6rem;
}
.cartbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #333;
  color: white;
  height: 5rem;
  padding: 1rem;
  a {
    color: white;
  }
  .cartlink {
    float: left;
  }
  .totalamount {
    float: right;
    font-size: 1.75rem;
  }
}
body.page-cart {
  padding-top: 0;
}
.page-cart .cartbar {
  display: none;
}
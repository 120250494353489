/*------------------------------------*\
  Paragraphs
\*------------------------------------*/

.view--display-event-front {
  .view__field--title {
    text-overflow: ellipsis-word; ;
    height: 90px;
  }
  .view__field--title, .field--node-event--body {
      background-color: rgba(195, 109, 37, 0.6);
      padding: 0.3em;
      p {
        margin-bottom: 0; 
        min-height: 173px;
      }
    }
}
.paragraphs-item-thirds-cards {
  margin-bottom: 8rem;
  color: $font-base-inverted;
  .card { 
    
    padding-left: 0 !important; 
    position: relative;
    &> div {
      padding: 3em 3em 0 3em; 
      min-height: 350px;
      text-align: center;
      h2 {
        font-weight: 100;
        margin-bottom: 1em;
        margin-top: 1em;
        color: $font-base-inverted;
        &:after {
          display: block; 
          content: "\00a0";
          width: 50px;
          height: 18px;
          margin: 0 auto;
          border-bottom: 2px solid $academy-orange-light; 
        }
      }
    }
  }
  .field-text-left {
    //background-color: $cs-grey-light;
  }
  .left.icon {
        position: absolute;
        bottom: -50px; 
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 200;
        width: fit-content; 
        display: inline-block;
    }
  .field-text-mid {
    //background-color: $academy-orange-light;
    color: $font-base-inverted;
    h2 {
      color: $font-base-inverted;
       &:after {
        border-bottom: 2px solid $academy-orange !important; 
      }
    }
  }
  .middle.icon {
        position: absolute;
        bottom: -50px; 
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 200;
        width: fit-content; 
        display: inline-block;
    }
  .field-text-right {
    //background-color: $cs-grey-light;
  }
  .right.icon {
        position: absolute;
        bottom: -50px; 
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 200;
        width: fit-content; 
        display: inline-block;
    }
}

.card:hover > div{
  background-color: rgba(241,132,15,0.8);
}
.paragraphs-item-thirds-fields {
  @media (max-width: $breakpoint-m-max) {
    .grid__item--12 {max-width: 390px;}
  }
  .background {
    background-size: cover; 
    text-align: center;
    a {
      margin: 12em auto; 
      display: inline-block;
    }
  }
}

@if $cmp-flexslider {
  /*------------------------------------*\
  FLEXSLIDER
\*------------------------------------*/
  /**
 * CONTENTS
 * BASE................
 */
  /*------------------------------------*\
  $BASE
\*------------------------------------*/
  /* Browser Resets
*********************************/
  .flex-container a:active,
  .flexslider a:active,
  .flex-container a:focus,
  .flexslider a:focus {
    outline: none;
  }
  .slides,
  .flex-control-nav,
  .flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  /* FlexSlider Necessary Styles
*********************************/
  .flexslider {
    margin: 0;
    padding: 0;
  }
  .flexslider .slides>li {
    display: none;
    -webkit-backface-visibility: hidden;
  }
  /* Hide the slides before the JS is loaded. Avoids image jumping */
  .flexslider .slides img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }
  .flex-pauseplay span {
    text-transform: capitalize;
  }
  /* Clearfix for the .slides element */
  .slides:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '\0020';
    line-height: 0;
  }
  html[xmlns] .slides {
    display: block;
  }
  * html .slides {
    height: 1%;
  }
  /* No JavaScript Fallback */
  /* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
  .no-js .slides>li:first-child {
    display: block;
  }
  .flexslider {
    position: relative;
    overflow: hidden;
    &:hover {
      .flex-prev,
      .flex-next {
        opacity: 0.6;
      }
    }
    .view__row {
      position: relative;
    }
    .slides>li {
      position: relative;
    }
  }
  .flex-control-paging a {
    cursor: pointer;
  }
  /**
 * Controls
 */
  .flex-prev,
  .flex-prev:hover,
  .flex-prev:visited,
  .flex-next,
  .flex-next:hover,
  .flex-next:visited {
    
  }
  .flex-prev,
  .flex-next {
    color: transparent !important;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 $spacing-m;
    height: 100%;
    text-decoration: none;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    .fa {
      position: relative;
      top: 45%;
    }
  }
  .flex-prev {
    &:before {
      color: $cs-grey !important; 
      content: "\f104";
      font-size: 3em;
      opacity: .6;
    }
  }
  .flex-next {
    right: 0;
    &:after {
     color: $cs-grey !important; 
     content: "\f105";
     font-size: 3em;
     opacity: .6;
    }
  }
  .flex-disabled {
    display: none;
  }
} // endif
.c-hero--align-left {
  text-align: left;
}

.c-hero {
  position: relative;
  height: 100vh;
  text-align: center;
  color: #fff;
  background-size: cover;
  @media (min-width: 45em) {
    height: 33.375em;
  }
   @media (min-width: 88em) {
    height: 44em;
  }
}

#flexslider-1 {
  .c-hero {
    background-position-x: right; 
  }
  .flex-outer {
    height: 100%;
  }
  .c-slideshow__caption {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    float: left;
    height: 100%;
    .flex-container {
      width: 65%;
      h1 {
        color: $font-base-inverted;
        font-weight: normal;
        font-size: 3.5em;
      }
      .field-slideshow-textarea {
        font-size: 1.1em;
        a {
          color: #fff; 
        }
      }
    }
  }
}

#carousel.flex1 .slides {
  li {
    margin: 10px 10px 10px 0;
    border: 1px solid $lightblue;
    height: 110px;
    width: 110px;
    align-items: center;
    display: flex !important;
  }
  img {
    padding: 5px;
    
  }
}

.flex1 {
  ul.slides {
    display: flex;
    align-items: center; 
    align-content: center;
  }
}


.field-teaser-image {
  display: flex;
  align-items: center; 
  align-content: center;
  height: 177px; 
  overflow: hidden;
}
.node-type--location {
  .cmp--hero {
    display: none;
  }

  .cmp--content {
    margin-top: 0;
  }
}

.field-location-geolocation,
.field-location-location {
  margin-bottom: 2em;
}

.organisation-name {
  font-weight: 700;
  margin-bottom: 1em;
  display: block;
}

.field-location-address  {
  margin-bottom: 1em;
}
.testimonial {
	h3 {
		margin-bottom: 1em;
	}
	.field-testimonial-image {
		img {
			border-radius: 160px;
		}
	}

	h4 {
		margin-top: 1em;
		&:before {
			content: "\f10d";
			display: inline-block;
		    font: normal normal normal 3rem/1 FontAwesome;
		    font-size: 2em;
		    color: $cs-grey;
		    text-rendering: auto;
		    -webkit-font-smoothing: antialiased;
		}
	}  
}
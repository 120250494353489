/*------------------------------------*\
  UTILITY
\*------------------------------------*/
/**
 * CONTENTS
 * VISIBILITY..........
 */



/*------------------------------------*\
  $VISIBILITY
\*------------------------------------*/
/**
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.element-invisible {
  @extend .u-visuallyhidden;
}

.u-text-align--right {
  text-align: right !important;
}
.u-display--flex {
  display: flex;
}
.u-align-items--center {
  align-items: center;
}

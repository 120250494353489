/*------------------------------------*\
  Page
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 */



.front {
	article {
		header {
			display: none;
		}
	}
}

.not-front {
	#title--main {
		display: none;
	}
}


@if $cmp-pager {

/*------------------------------------*\
  PAGER
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

.pager {
  @extend .u-list-reset;
  text-align: center;
  a {
    text-decoration: none;
  }
  .pager__item {
    display: inline-block;
    margin: 0 0.25em;
  }
  .pager__item--current {
    font-weight: $font-a-weight-bold;
  }
}

} // endif
/*------------------------------------*\
  $VARIABLES
\*------------------------------------*/
/**
 * CONTENTS
 * COLOR-SHEME.........Set theme colors
 * TYPOGRAPHY..........
 * BREAKPOINTS.........
 * SPACING.............
 * LAYOUT..............
 * COMPONENTS..........
 * SCAFFOLDING.........
 */



/*------------------------------------*\
  $COLOR-SHEME
\*------------------------------------*/
/**
 * Basic colors
 * Fancy generator: https://kuler.adobe.com
 */
$lightblue: #089bd9;
$darkblue: #003866;
$headlines: #3c3f46;
$H3: #7e8186;
$font-base: #7e8186;
$font-base-inverted: #ffffff;
$tag-gray: #cfd7d9;
$download-bg: #f6fafb;
$academy-orange: #f1840f;
$academy-orange-light: #f1840f;

$cs-a: $academy-orange-light;
$cs-b: $academy-orange;
$cs-c: $font-base;

/**
 * Colors for alerts
 * e.g. success, error, info
 */
$cs-positive: #468847;
$cs-neutral:  #3A87AD;
$cs-cautious: #c09853;
$cs-negative: #b94a48;

/**
 * 3 Shades of Grey
 */
$cs-grey:       #a9a9a9;
$cs-grey-dark:  darken($cs-grey, 15%);
$cs-grey-light: #f5f5f5;



/*------------------------------------*\
  $TYPOGRAPHY
\*------------------------------------*/
/**
 * Set the font weight variables according to the font
 * e.g. http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700
 * In this case set the font weights to 200, 400 and 700
 *
 * If you set a font weight in your css you must use this variables!
 * e.g. font-weight: $font-a-weight-normal;
 *
 * 1. must be in px, do not use in your styles
 */
$font-base-size:        14px; /* [1] */
$font-base-line-height: 26px; /* [1] */
$font-base-color:       $font-base;

/**
 * Font-sizes
 */
$font-size-xs:   0.75rem;
$font-size-s:    0.85rem;
$font-size-m:    1rem;
$font-size-l:    1.3rem;
$font-size-xl:   1.5rem;
$font-size-xxl:  1.75rem;
$font-size-xxxl: 2.5rem;

$font-size-h1: $font-size-xxxl;
$font-size-h2: $font-size-l;
$font-size-h3: $font-size-xl;
$font-size-h4: $font-size-l;
$font-size-h5: $font-size-m;
$font-size-h6: $font-size-m;

/**
 * Primary font
 */
$font-a-family: 'Noto Serif', sans-serif;

$font-a-weight-thin:        400;
$font-a-weight-extra-light: 400;
$font-a-weight-light:       400;
$font-a-weight-normal:      400;
$font-a-weight-medium:      400;
$font-a-weight-semi-bold:   700;
$font-a-weight-bold:        700;
$font-a-weight-extra-bold:  700;
$font-a-weight-ultra-bold:  700;

/**
 * Secondary font
 */
$font-b-family: 'Noto Sans', sans-serif;

$font-b-weight-thin:        400;
$font-b-weight-extra-light: 400;
$font-b-weight-light:       400;
$font-b-weight-normal:      400;
$font-b-weight-medium:      400;
$font-b-weight-semi-bold:   700;
$font-b-weight-bold:        700;
$font-b-weight-extra-bold:  700;
$font-b-weight-ultra-bold:  700;

/**
 * Anchors
 */
$a-color:                 $academy-orange;
$a-color-visited:         $academy-orange;
$a-color-hover:           $academy-orange;
$a-text-decoration:       none;
$a-text-decoration-hover: underline;

/**
 * Text selection
 */
$selection-background: $academy-orange;
$selection-color:      $font-base;



/*------------------------------------*\
  $BREAKPOINTS
\*------------------------------------*/
/**
 * Breakpoints
 */
$breakpoint-s:  480px;
$breakpoint-m:  720px;
$breakpoint-l:  960px;
$breakpoint-xl: 1200px;

$breakpoint-s-max:  $breakpoint-s - 1;
$breakpoint-m-max:  $breakpoint-m - 1;
$breakpoint-l-max:  $breakpoint-l - 1;
$breakpoint-xl-max: $breakpoint-xl - 1;



/*------------------------------------*\
  $SPACING
\*------------------------------------*/
/**
 * Basic spacing
 */
$spacing-xs:   0.5em;
$spacing-s:    1em;
$spacing-m:    1.25em;
$spacing-l:    2em;
$spacing-xl:   3em;
$spacing-xxl:  4em;
$spacing-xxxl: 5em;



/*------------------------------------*\
  $LAYOUT
\*------------------------------------*/

$layout-max-width: 78em;
$layout-box-sizing-reset: true;



/*------------------------------------*\
  $COMPONENTS
\*------------------------------------*/
/**
 * Grid
 */
$cmp-grid: true;

$cmp-grid-item-count: 12;

// Sizes
$cmp-grid-size-s:  false;
$cmp-grid-size-m:  true;
$cmp-grid-size-l:  true;
$cmp-grid-size-xl: false;

// Spacing between items
$cmp-grid-spacing-s:  $spacing-s;
$cmp-grid-spacing-m:  $spacing-m;
$cmp-grid-spacing-l:  $spacing-m;
$cmp-grid-spacing-xl: $spacing-m;

/**
 * Button
 */
$cmp-button: true;

// Sizes
$cmp-button-size-s:    false;
$cmp-button-size-l:    false;
$cmp-button-size-xl:   false;
$cmp-button-size-full: false;

// Font sizes
$cmp-button-font-size-s:  false;
$cmp-button-font-size-l:  false;
$cmp-button-font-size-xl: false;

// Functions
$cmp-button-function-positive: false;
$cmp-button-function-neutral:  false;
$cmp-button-function-cautious: false;
$cmp-button-function-negative: false;
$cmp-button-function-inactive: false;
$cmp-button-function-disabled: false;

/**
 * Menu
 */
$cmp-menu: true;

// Breakpoint for mobile version of menu
$cmp-menu-mobile-breakpoint: $breakpoint-m;

/**
 * Island
 */
$cmp-island: false;

// Functions
$cmp-island-function-positive: false;
$cmp-island-function-neutral:  false;
$cmp-island-function-cautious: false;
$cmp-island-function-negative: false;

// Color Sheme
$cmp-island-cs-a: false;
$cmp-island-cs-b: false;
$cmp-island-cs-c: false;
$cmp-island-cs-d: false;
$cmp-island-cs-e: false;

/**
 * Media
 */
$cmp-media: true;

/**
 * Skiplinks
 */
$cmp-skiplinks: true;

/**
 * Pager
 */
$cmp-pager: true;

/**
 * Message
 */
$cmp-message: true;

/**
 * CKEditor
 */
$cmp-ckeditor: true;

/**
 * Tabs
 */
$cmp-tabs: true;

/**
 * Flexslider
 */
$cmp-flexslider: true;

/**
 * Flexslider
 */
$cmp-grid-spacing: true; 

/**
 * Row style
 */
$cmp-row-style: true;



/*------------------------------------*\
  $SCAFFOLDING
\*------------------------------------*/

@import '../../libraries/avalanche/src/system/scaffolding';
@import 'system/scaffolding';

@if $cmp-grid {

/*------------------------------------*\
  GRID
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 * SMALL...............
 * MEDIUM..............
 * LARGE...............
 * XLARGE..............
 *
 *
 * <div class="grid">
 *   <div class="grid__item--6 grid__item--m--3">
 *     [optional]<div class="grid__inner"></div>[/optional]
 *   </div>
 *   <div class="grid__item--6 grid__item--m--3">
 *     [optional]<div class="grid__inner"></div>[/optional]
 *   </div>
 *   <div class="grid__item--12 grid__item--s--6">
 *     [optional]<div class="grid__inner"></div>[/optional]
 *   </div>
 *   <div class="grid__item--12 grid__item--s--6">
 *     [optional]<div class="grid__inner"></div>[/optional]
 *   </div>
 * </div>
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/
/**
 * Styles for all devices
 */
$cmp-grid-base-size: (100% / $cmp-grid-item-count);

.grid {
  @extend .u-cf;
  @if $cmp-grid-size-m == false {
    margin-left: -$cmp-grid-spacing-m;
  }
  @media (max-width: $breakpoint-m-max) {
    margin-left: -$cmp-grid-spacing-s;
  }
}

[class*='grid__item'] {
  float: left;
  width: 100%;
  @if $layout-box-sizing-reset == false {
    box-sizing: border-box;
  }
  @if $cmp-grid-size-m == false {
    padding-left: $cmp-grid-spacing-m;
  }
  @media (max-width: $breakpoint-m-max) {
    padding-left: $cmp-grid-spacing-s;
  }
}

@for $i from 1 through $cmp-grid-item-count {
  .grid__item--#{$i} {
    width: ($cmp-grid-base-size * $i);
  }
}



@if $cmp-grid-size-s {
  /*------------------------------------*\
    $SMALL
  \*------------------------------------*/
  @media (min-width: $breakpoint-s) {
    @for $i from 1 through $cmp-grid-item-count {
      .grid__item--s--#{$i} {
        width: ($cmp-grid-base-size * $i);
      }
    }
  }
}



@if $cmp-grid-size-m {
  /*------------------------------------*\
    $MEDIUM
  \*------------------------------------*/
  @media (min-width: $breakpoint-m) {
    .grid {
      margin-left: -$cmp-grid-spacing-m;
    }

    [class*='grid__item'] {
      padding-left: $cmp-grid-spacing-m;
    }

    @for $i from 1 through $cmp-grid-item-count {
      .grid__item--m--#{$i} {
        width: ($cmp-grid-base-size * $i);
      }
    }
  }
}



@if $cmp-grid-size-l {
  /*------------------------------------*\
    $LARGE
  \*------------------------------------*/
  @media (min-width: $breakpoint-l) {
    .grid {
      margin-left: -$cmp-grid-spacing-l;
    }

    [class*='grid__item'] {
      padding-left: $cmp-grid-spacing-l;
    }

    @for $i from 1 through $cmp-grid-item-count {
      .grid__item--l--#{$i} {
        width: ($cmp-grid-base-size * $i);
      }
    }
  }
}



@if $cmp-grid-size-xl {
  /*------------------------------------*\
    $XLARGE
  \*------------------------------------*/
  @media (min-width: $breakpoint-xl) {
    .grid {
      margin-left: -$cmp-grid-spacing-xl;
    }

    [class*='grid__item'] {
      padding-left: $cmp-grid-spacing-xl;
    }

    @for $i from 1 through $cmp-grid-item-count {
      .grid__item--l--#{$i} {
        width: ($cmp-grid-base-size * $i);
      }
    }
  }
}

} // endif
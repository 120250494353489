/*------------------------------------*\
  FORM
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 * DATE................
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

@media(min-width: 640px) {
  #user-register-form {
    width: 50%;
}
}
.form__item {

  margin-bottom: $spacing-s;
  input, select {
    border: 1px solid #ccc !important;
  }
  .form__item {
    margin-bottom: 0;

  }
}

.form__wrapper {
  margin-bottom: 0;
}

.form__label,
.form__fieldset-legend {
  font-weight: bold;
  font-size: 1em;
}




/*------------------------------------*\
  $DATE
\*------------------------------------*/

.form__item--date-select {
  .form__item {
    display: inline-block;
    margin-right: $spacing-xs;
    margin-bottom: 0;
  }
  .form__label {
    font-weight: $font-a-weight-normal;
  }
}

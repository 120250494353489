@if $cmp-grid-spacing {

/*------------------------------------*\
  GRID-SPACING
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

.grid-spacing {
  margin-top: -$cmp-grid-spacing-s;

  &.grid--condensed {
    margin-top: -0.875em;
  }
}

.grid-spacing__item {
  margin-top: $cmp-grid-spacing-s;

  .grid--condensed & {
    margin-top: 0.875em;
  }
}

// small
@media only screen and (min-width: $breakpoint-s) {
  .grid-spacing {
    margin-top: -$cmp-grid-spacing-s;
  }

  .grid-spacing__item {
    margin-top: $cmp-grid-spacing-s;
  }
}

// medium
@media only screen and (min-width: $breakpoint-m) {
  .grid-spacing {
    margin-top: -$cmp-grid-spacing-m;
  }

  .grid-spacing__item {
    margin-top: $cmp-grid-spacing-m;
  }
}

// large
@media only screen and (min-width: $breakpoint-l) {
  .grid-spacing {
    margin-top: -$cmp-grid-spacing-l;
  }

  .grid-spacing__item {
    margin-top: $cmp-grid-spacing-l;
  }
}

} // endif
.academy-block {
  background: $cs-grey-light;
  padding: 3em 0;
    .side-graphics{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      margin-top: 5px;
      .domain-afs-academy-at & {
        background: $cs-b;
      }
      .domain-afs-medical-at & {
        background: $cs-a;
      }

    }
  .fa {
    background: $lightblue;
    color: white;
    padding: 0.6em;
    border-radius: 2em;
    margin-top: 3px;
  }
  h2,
  h4 {
    font-weight: 100;
  }
  h2 {
    font-size: 2rem;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }
  .col3 {
    margin-top: 1em;
  }
  .academy-block-app {
    margin-top: 4em;
    @media (max-width: 625px) {
      a {
        float: none !important;
        margin-bottom: 1em;
        display: inline-block;
      }
    }
  }
}

.membership {
  text-align: center;
  h4 {
    font-size: 1em;
    margin: 1.5em 0 1em 0;
  }
}

/*------------------------------------*\
  TYPOGRAPHY
\*------------------------------------*/
/**
 * CONTENTS
 * HEADLINES...........
 * ANCHORS.............
 * WHITESPACE..........
 */



/*------------------------------------*\
  $HEADLINES
\*------------------------------------*/
/**
 * Reset margin on headlines
 */
h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: $spacing-xs;
  color: $cs-a;
  font-weight: $font-b-weight-bold;
  font-family: $font-b-family;
  line-height: 1.25;
  text-transform: uppercase;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  margin: 0;
  font-size: $font-size-h5;
}

h6 {
  margin: 0;
  font-size: $font-size-h6;
}



/*------------------------------------*\
  $ANCHORS
\*------------------------------------*/
/**
 * <span class="a">readmore...</span>
 */
a,
.a {
  color: $a-color;
  text-decoration: $a-text-decoration;
  &:visited {
    color: $a-color-visited;
  }
  &:hover,
  &:focus {
    color: $a-color-hover;
    text-decoration: $a-text-decoration-hover;
  }
}

/**
 * Reversed link behaviour
 */
.a--reversed {
  text-decoration: $a-text-decoration-hover;
  &:hover,
  &:focus {
    text-decoration: $a-text-decoration;
  }
}



/*------------------------------------*\
  $WHITESPACE
\*------------------------------------*/
/**
 * Add whitespace around some block elements
 */
p,
ul,
ol,
dl,
pre,
form,
table,
figure,
address,
fieldset,
blockquote {
  margin-top: 0;
  margin-bottom: (($font-base-line-height / $font-base-size) * 1em);
}

ul,
ol,
dl {
  padding-left: 1.5em;
}

/**
 * no margin for nested lists
 */
li {
  ul,
  ol,
  dl {
    margin-bottom: 0;
  }
}

/**
 * no margin for nested tables
 */
table {
  table {
    margin-bottom: 0;
  }
}

/**
 * Normalize default font related tags
 */
b,
strong {
  font-weight: $font-a-weight-bold;
}

small {
  font-size: $font-size-s;
}
@if $cmp-row-style {

/*------------------------------------*\
  ROW-STYLE
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

.row-style__item:not(:last-child) {
  margin-bottom: $spacing-l;
  padding-bottom: $spacing-l;
  border-bottom: 1px dotted $cs-grey;
  .row-style--condensed & {
    margin-bottom: $spacing-s;
    padding-bottom: $spacing-s;
  }
}

.top-margin {
	margin-top: 3em;
}
.center {
  text-align: center; 
}

} // endif
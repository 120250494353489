#block--language {
	ul {
		padding-left: 0;
		margin: 0;
		float: left; 
		li {
			list-style-type: none;
			display: inline-block;
			margin: 0;
			a {
				color: $font-base-inverted;
				padding-right: 5px;
			}
			&:not(.first) {				
				a {
					border-left: 1px solid $cs-grey-light;
					padding-left: 6px;
				}
			} 
		}
	}
} 
.event {
  .event-top-label {
    color: $academy-orange;
    margin-bottom: 5px;
  }

  .field-title-field {
    margin-bottom: 0;
    @extend h1;
  }

  .field-event-date {
    font-family: $font-b-family;
    font-weight: 100;
    @extend h1;
  }

  .field-body {
    color: black;
    margin-top: 20px;

    ul {
      padding: 0;
      list-style-type: none;

      li {
        font-weight: bold;

        &:before {
          content: "\2014";
          padding-right: 1em;
        }
      }
    }
  }

  .field-programm-als-pdf {
    padding: 1.2em;
    border: 1px solid $academy-orange;
    display: inline-block;
    margin-bottom: 2em;

    a {
      color: $font-base;
    }
  }

  .event-file-btn {
    background: none;
    padding: 1em 2em;
    border: 5px solid $academy-orange;
    margin-bottom: 2em;
    margin-top: 20px;
    font-family: 'Noto Serif', sans-serif;
    text-transform: none;
    font-size: 17px;

    &:before {
      content: "\f133";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      padding: 1em;
      border-radius: 2em;
      background-color: $academy-orange;
      color: #fff;
    }

    a {
      color: black;
      margin-left: 10px;
      padding: 0 3px 4px;
      border-bottom: 1px solid $font-base;

      &:hover {
        text-decoration: none;
      }

      &:after {
        content: "\f019";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        margin-left: 15px;
      }
    }
  }

  .event-details {
    border: 5px solid $academy-orange;
    margin-bottom: 2em;
    text-align: right;
    color: black;

    h3 {
      text-align: left;
      border-bottom: 1px solid $academy-orange;
      padding: 10px 1em;
      margin-bottom: 25px;
      color: black !important;
      font-family: 'Noto Serif', sans-serif;
      font-weight: normal;
    }

    .event-details-header {
      color: $academy-orange;
      font-weight: bold;
      padding: 0 1em;
      .event-taxdetails {
        color: black; 
        font-size: 0.8em;
      }
    }
    .event-details-price-type{
      padding: 0 1em;
    }

    .event-details-date {
      margin: 0 0 20px;
      padding: 0 1em;
      font-weight: bold;
    }

    .event-details-price {
      padding: 0 1em;
      font-size: 14px;
      margin-bottom: 5px;
      &.netto {
      margin-bottom: 20px;
      font-weight: bold;
      }
    }

    .event-details-addtocart {
      padding: 0 1em;
    }

    .event-storno {
      padding: 0 1em 1em;
      line-height: 20px;
    }

    .headline-border {
      width: 100%;
      height: 1px;
      border: $academy-orange;
    }

    .field-location-address {
      padding: 0 1em;
      margin-bottom: 20px;
    }

    .cancellation-policy {
      color: grey;
      margin: 15px;
      font-size: 13px;
      display: inline-block;

      &:before {
        content: "*";
        padding-right: 2px;
        display: inline-block;
        font-size: 0.8em;
        vertical-align: super;
      }
    }
  }

  h1 {
    margin-bottom: 0;
    font-weight: normal;
  }

  h3 {
    font-family: $font-b-family;
    color: $academy-orange !important;

    &.c-program {
      margin-top: 30px;
    }
  }
}
  .field-event-type {
    font-weight: bold;
    color: $academy-orange;
  }

  .view--events--page {
    h1 {
      margin-bottom: 1.5em;
    }

    .view__row {
      &:not(:first-child) {
        padding-top: 2em;
      }
      padding-bottom: 2em;

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      .field-event-date {
        font-weight: bold;
        color: $cs-a;
      }
    }
  }

  .checkout-help {
    margin-bottom: 2em;
    font-weight: bold;
  }

  .cart_contents,
  .checkout-completion-message,
  .checkout_review {
    padding: 2em;
    /* border-radius: 20px; */
    background: #f3f3f3;
    margin-bottom: 2em;

    .form__fieldset-legend {
      display: none;
    }
  }

  .checkout_review {
    .pane-title {
      font-weight: bold;
      display: block;
      padding-top: 2em;
    }
  }

  .views-field-line-item-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
  }

  .view--commerce-user-orders table,
  .view--order-coupon-list table {
    width: 100%;
    margin-top: 0.5em;

    td,
    th {
      border: 1px solid #ccc;
      text-align: left;
      background: white;
      padding: 0.5em;
    }

    ul {
      padding: 0;

      li {
        list-style-type: none;
      }
    }
  }

  .commerce-order-commerce-order,
  .view--commerce-cart-form--default__content {
    .views-table {
      width: 100%;
    }

    .views-field-line-item-title {
      font-size: 1rem;
    }

    th {
      background: #f3f3f3;
      padding: 1em;
      font-size: 1rem;
      font-weight: normal;
      text-align: left;
      color: black;
      font-weight: bold;
      text-align: right;
    }

    td {
      padding: 1rem;
      border: 1px solid #ccc;
      text-align: right;
    }

    td:first-child,
    th:first-child {
      text-align: left;
    }

    .commerce-price-formatted-components {}
  }

  .commerce-price-formatted-components {
    text-align: right;
    float: right;
  }

  #commerce-checkout-coupon-ajax-wrapper,
  #commerce-checkout-form-checkout .group-additional,
  .form__field--name-commerce-customer-address,
  .form__field--name-field-alternate-invoice-address {
    padding: 2em;
    background: #f3f3f3;
  }

  #commerce-checkout-coupon-ajax-wrapper,
  #commerce-checkout-form-checkout {
  h3 {
    font-weight: bold;
    font-size: 1em;
    font-family: "Noto Sans", sans-serif;
    color: #7e8186;
  }
    fieldset {
    margin-bottom: 2em;
  }
}
  .checkout-review {
    h3 {
    font-weight: bold;
    font-size: 1em;
    font-family: "Noto Sans", sans-serif;
    color: #7e8186;
    }
    .field-checkbox-alt-invoice, .field-alternate-invoice-address, .field-cancellation-policy, .field-book-hotel, .field-amic-member{ 
      display: none;
    } 
  }

  .checkout-continue {
    float: right;
  }

  .button-operator {
    display: none;
  }

  .event-details-addtocart .button.form__submit {
    width: 100%;
    font-weight: bold;
  }

  .component-type-commerce-price-formatted-amount {
    td.component-title {
      font-weight: bold;
      padding-right: 10px;
    }

    td.component-total {
      font-size: 2em;
    }
  }

  .commerce-line-item-actions {
    display: flex;
    justify-content: space-between;
  }

  .line-item-summary {
    text-align: right;
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  .form__item--customer-profile-billing-field-checkbox-alt-invoice-und {
    margin-top: 2em;
  }

  .view--events--attachment-1__content {
    display: flex;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;

    .view-grouping {
      
    }

    .view-grouping-header {}
  }

  .block-views-exp-events-page {
    .views-exposed-widgets {
      display: flex;
      justify-content: space-between;
      background: #f3f3f3;
      width: 100%;
      padding: 1.5em;
      align-items: center;
    }

    input,
    select {
      width: auto;
    }
  }

  div.tab {
    background: none;
    color: #222;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
  }

  div.tab.current {
    color: #fff;
    background: $academy-orange;
  }

  div.tab-content {
    display: none;
    padding: 15px; 

    .date-display-single {
      display: none;
    }
  }

  div.tab-content.current {
    display: inherit;
  }

  input#edit-event-code {
    border: 1px solid $academy-orange;
    text-align: center;
    font-family: $font-b-family;
    font-size: 12px;
  }

  .group-hosts-image {
    width: 120px;
    margin-right: 20px;
    float: left;
  }

  .group-hosts-detail {
    height: 80px;
  }

  .field--node-hosts--title-field {
    font-size: 1.3rem !important;
    color: black !important;
    font-family: $font-b-family !important;
    font-weight: bold !important;
  }

  .c-hosts-list {
    margin: 50px 0 60px;
    .links.inline {display: none;}
    article {
      /*margin-bottom: 20px;*/
      clear: both;
      overflow: auto;

      header {
        display: none;

        h2 {
          font-family: $font-b-family;
          margin-bottom: 0;
        }
      }
    }
  }

  .field-host-profile {
    color: black;
  }

  .field-time {
    width: 15%;
    float: left;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .field-event-plan {
    width: 85%;
    float: left;
    margin-bottom: 20px;
  }

  .field-event-program {
    color: black;
  }

  .field-event-location {
    height: 250px;
  }

  .field-event-location > div {
    height: 250px !important;
  }


#event-storno {
  h3 {
    font-family: $font-b-family;
    font-size: 1.5rem;
    color: $academy-orange;
  }

  .event-storno {
    line-height: 20px;
    color: black;

    small {
      b {
        font-weight: normal;
      }
    }
  }
}

#rm--mobile-month-selector {
  display: none;
}

@media (max-width: ($cmp-menu-mobile-breakpoint - 1)) {
#rm--mobile-month-selector {
  display: block;
}
.view--events--page .view__attachment {
  display: none;
}
.block-views-exp-events-page .views-exposed-widgets{
  display: block;
}
.view--events--page__content article>.u-display--flex {
  display: block;
  &>div {
    width: 100%;
  }
}
}
 
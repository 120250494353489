@if $cmp-media {

/*------------------------------------*\
  MEDIA
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 *
 *
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 *
 * <div class="media">
 *   <a href="#" class="media__img">
 *     <img src="pony.png">
 *   </a>
 *   [optional]
 *   <div class="media__img--rev">
 *     <img src="pony2.png">
 *   </div>
 *   [/optional]
 *   <div class="media__body">
 *     <p>Lorem Ipsum</p>
 *   </div>
 * </div>
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

.media {
  @extend .u-cf;
}

/**
 * Reset media styling on small screens
 */
@media (max-width: $breakpoint-m-max) {
  .media--mobile-reset {
    .media__img {
      float: none;
      margin-right: 0;
    }
    .media__img--rev {
      float: none;
      margin-left: 0;
    }
  }
}

.media__img {
  float: left;
  margin-right: $spacing-m;
}

/**
 * Reversed image location (right instead of left).
 */
.media__img--rev {
  float: right;
  margin-left: $spacing-m;
}

.media__img,
.media__img--rev {
  img {
    display: block;
  }
}

.media__body {
  overflow: hidden;
}

} // endif
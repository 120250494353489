/*------------------------------------*  $VARIABLES
\*------------------------------------*/
/** CONTENTS COLOR-SHEME.........Set theme colors TYPOGRAPHY.......... BREAKPOINTS......... SPACING............. LAYOUT.............. COMPONENTS.......... SCAFFOLDING......... */
/*------------------------------------*  $COLOR-SHEME
\*------------------------------------*/
/** Basic colors Fancy generator: https://kuler.adobe.com */
/** Colors for alerts e.g. success, error, info */
/** 3 Shades of Grey */
/*------------------------------------*  $TYPOGRAPHY
\*------------------------------------*/
/** Set the font weight variables according to the font e.g. http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700 In this case set the font weights to 200, 400 and 700 If you set a font weight in your css you must use this variables! e.g. font-weight: $font-a-weight-normal; 1. must be in px, do not use in your styles */
/* [1] */
/* [1] */
/** Font-sizes */
/** Primary font */
/** Secondary font */
/** Anchors */
/** Text selection */
/*------------------------------------*  $BREAKPOINTS
\*------------------------------------*/
/** Breakpoints */
/*------------------------------------*  $SPACING
\*------------------------------------*/
/** Basic spacing */
/*------------------------------------*  $LAYOUT
\*------------------------------------*/
/*------------------------------------*  $COMPONENTS
\*------------------------------------*/
/** Grid */
/** Button */
/** Menu */
/** Island */
/** Media */
/** Skiplinks */
/** Pager */
/** Message */
/** CKEditor */
/** Tabs */
/** Flexslider */
/** Flexslider */
/** Row style */
/*------------------------------------*  $SCAFFOLDING
\*------------------------------------*/
/*------------------------------------*  SCAFFOLDING
\*------------------------------------*/
/** CONTENTS SYSTEM.............. BASE................ COMPONENTS.......... */
/*------------------------------------*  $SYSTEM
\*------------------------------------*/
/*------------------------------------*  FUNCTIONS
\*------------------------------------*/
/** CONTENTS STRIP-UNITS......... PIXEL2EM............ EM2PIXEL............ EM2REM.............. */
/*------------------------------------*  $STRIP-UNITS
\*------------------------------------*/
/*------------------------------------*  $PIXEL2EM
\*------------------------------------*/
/** Convert a px value to em based on $font-base-size */
/*------------------------------------*  $EM2PIXEL
\*------------------------------------*/
/** Convert a em value to px based on $font-base-size */
/*------------------------------------*  $EM2REM
\*------------------------------------*/
/** Convert a em value to rem */
/*------------------------------------*  $BASE
\*------------------------------------*/
/*------------------------------------*  BASE
\*------------------------------------*/
/** CONTENTS ELEMENTS............ SELECTION........... */
/*------------------------------------*  $ELEMENTS
\*------------------------------------*/
html { font-size: 87.5%; line-height: 1.8571429; }

html, button, input, select, textarea { color: #7e8186; font-family: "Noto Serif", sans-serif; }

/* A better looking default horizontal rule */
hr { display: block; margin: 1em 0; padding: 0; height: 1px; border: 0; border-top: 1px solid #a9a9a9; }

/* Remove the gap between images, videos, audio and canvas and the bottom of their containers: h5bp.com/i/440 */
audio, canvas, img, svg, video { vertical-align: middle; }

/* Remove default fieldset styles. 1. Chrome and Firefox set a `min-width: min-content;` on fieldsets, so we reset that to ensure it behaves more like a standard block element. See https://github.com/twbs/bootstrap/issues/12359. */
fieldset { margin: 0; padding: 0; min-width: 0; /* [1] */ border: 0; }

/* Allow only vertical resizing of textareas. */
textarea { resize: vertical; }

/*------------------------------------*  $SELECTION
\*------------------------------------*/
/* Remove text-shadow in selection highlight: h5bp.com/i These selection rule sets have to be separate. Customize the background color to match your design. */
::-moz-selection { background: #f1840f; color: #7e8186; text-shadow: none; }

::selection { background: #f1840f; color: #7e8186; text-shadow: none; }

/*------------------------------------*  FORM
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
label { display: block; }

select, textarea, input[type='text'], input[type='password'], input[type='datetime'], input[type='datetime-local'], input[type='date'], input[type='month'], input[type='time'], input[type='week'], input[type='number'], input[type='email'], input[type='url'], input[type='search'], input[type='tel'], input[type='color'] { display: inline-block; padding: 0.5em; max-width: 100%; border: 1px solid #838383; background-image: none; transition: border-color ease-in-out 0.1s, box-shadow ease-in-out 0.1s; }

select:focus, textarea:focus, input[type='text']:focus, input[type='password']:focus, input[type='datetime']:focus, input[type='datetime-local']:focus, input[type='date']:focus, input[type='month']:focus, input[type='time']:focus, input[type='week']:focus, input[type='number']:focus, input[type='email']:focus, input[type='url']:focus, input[type='search']:focus, input[type='tel']:focus, input[type='color']:focus { outline: none; border-color: #f1840f; box-shadow: 0 0 0.375em #f1840f; }

input[type='radio'], input[type='checkbox'] { margin-top: 1px \9; line-height: normal; }

input[type='radio'] + label, input[type='checkbox'] + label { display: inline-block; }

input[type='file'] { display: block; }

input[type='range'] { display: block; width: 100%; }

select[multiple], select[size] { height: auto; }

input[type='file']:focus, input[type='radio']:focus, input[type='checkbox']:focus { border: 3px solid red; }

textarea { height: auto; }

/*------------------------------------*  LAYOUT
\*------------------------------------*/
/** CONTENTS CONTAINER........... BOX-SIZING-RESET.... */
/*------------------------------------*  $CONTAINER
\*------------------------------------*/
.container { margin: 0 auto; padding: 0 1em; max-width: 78em; }

/*------------------------------------*  $BOX-SIZING-RESET
\*------------------------------------*/
html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

/*------------------------------------*  TYPOGRAPHY
\*------------------------------------*/
/** CONTENTS HEADLINES........... ANCHORS............. WHITESPACE.......... */
/*------------------------------------*  $HEADLINES
\*------------------------------------*/
/** Reset margin on headlines */
h1, .event .field-title-field, .event .field-event-date, h2, h3, h4 { margin-top: 0; margin-bottom: 0.5em; color: #f1840f; font-weight: 700; font-family: "Noto Sans", sans-serif; line-height: 1.25; text-transform: uppercase; }

h1, .event .field-title-field, .event .field-event-date { font-size: 2.5rem; }

h2 { font-size: 1.3rem; }

h3 { font-size: 1.5rem; }

h4 { font-size: 1.3rem; }

h5 { margin: 0; font-size: 1rem; }

h6 { margin: 0; font-size: 1rem; }

/*------------------------------------*  $ANCHORS
\*------------------------------------*/
/** <span class="a">readmore...</span> */
a, .a { color: #f1840f; text-decoration: none; }

a:visited, .a:visited { color: #f1840f; }

a:hover, a:focus, .a:hover, .a:focus { color: #f1840f; text-decoration: underline; }

/** Reversed link behaviour */
.a--reversed { text-decoration: underline; }

.a--reversed:hover, .a--reversed:focus { text-decoration: none; }

/*------------------------------------*  $WHITESPACE
\*------------------------------------*/
/** Add whitespace around some block elements */
p, ul, ol, dl, pre, form, table, figure, address, fieldset, blockquote { margin-top: 0; margin-bottom: 1.8571429em; }

ul, ol, dl { padding-left: 1.5em; }

/** no margin for nested lists */
li ul, li ol, li dl { margin-bottom: 0; }

/** no margin for nested tables */
table table { margin-bottom: 0; }

/** Normalize default font related tags */
b, strong { font-weight: 700; }

small { font-size: 0.85rem; }

/*------------------------------------*  UTILITY
\*------------------------------------*/
/** CONTENTS CLEARFIX............ LIST-RESET.......... SPACING............. VISIBILITY.......... DISPLAY............. */
/*------------------------------------*  CLEARFIX
\*------------------------------------*/
/** Clearfix Contain floats */
.u-cf, .grid, .menu--horizontal .menu__list--level1, .media, .tabs, .clearfix { *zoom: 1; }

.u-cf:before, .grid:before, .menu--horizontal .menu__list--level1:before, .media:before, .tabs:before, .clearfix:before, .u-cf:after, .grid:after, .menu--horizontal .menu__list--level1:after, .media:after, .tabs:after, .clearfix:after { display: table; content: ' '; }

.u-cf:after, .grid:after, .menu--horizontal .menu__list--level1:after, .media:after, .tabs:after, .clearfix:after { clear: both; }

/*------------------------------------*  $LIST-RESET
\*------------------------------------*/
.u-list-reset, .menu__list, .pager, .tabs { padding-left: 0; list-style-type: none; }

/*------------------------------------*  $SPACING
\*------------------------------------*/
/** Bottom */
.u-spacing--bottom--xs { margin-bottom: 0.5em; }

.u-spacing--bottom--s { margin-bottom: 1em; }

.u-spacing--bottom--m { margin-bottom: 1.25em; }

.u-spacing--bottom--l { margin-bottom: 2em; }

.u-spacing--bottom--xl { margin-bottom: 3em; }

.u-spacing--bottom--xxl { margin-bottom: 4em; }

.u-spacing--bottom--xxxl { margin-bottom: 5em; }

.u-spacing--bottom--off { margin-bottom: 0; }

/** Inner */
.u-spacing--inner--both--xs { padding-top: 0.5em; padding-bottom: 0.5em; }

.u-spacing--inner--both--s { padding-top: 1em; padding-bottom: 1em; }

.u-spacing--inner--both--m { padding-top: 1.25em; padding-bottom: 1.25em; }

.u-spacing--inner--both--l { padding-top: 2em; padding-bottom: 2em; }

.u-spacing--inner--both--xl { padding-top: 3em; padding-bottom: 3em; }

.u-spacing--inner--both--xxl { padding-top: 4em; padding-bottom: 4em; }

.u-spacing--inner--both--xxxl { padding-top: 5em; padding-bottom: 5em; }

/*------------------------------------*  $VISIBILITY
\*------------------------------------*/
/** Hide from both screenreaders and browsers: h5bp.com/u */
.u-hidden { display: none !important; visibility: hidden; }

/** Hide only visually, but have it available for screenreaders: h5bp.com/v */
.u-visuallyhidden, .element-invisible { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; padding: 0; width: 1px; height: 1px; border: 0; }

.u-visuallyhidden.u-focusable:active, .u-focusable.element-invisible:active, .u-visuallyhidden.u-focusable:focus, .u-focusable.element-invisible:focus { position: static; overflow: visible; clip: auto; margin: 0; width: auto; height: auto; }

/** Hide visually and from screenreaders, but maintain layout */
.u-invisible { visibility: hidden; }

/*------------------------------------*  $DISPLAY
\*------------------------------------*/
.u-display-block { display: block; }

.u-display-inline { display: inline; }

.u-display-inline-block { display: inline-block; }

/*------------------------------------*  $COMPONENTS
\*------------------------------------*/
/*------------------------------------*  GRID
\*------------------------------------*/
/** CONTENTS BASE................ SMALL............... MEDIUM.............. LARGE............... XLARGE.............. <div class="grid"> <div class="grid__item--6 grid__item--m--3"> [optional]<div class="grid__inner"></div>[/optional] </div> <div class="grid__item--6 grid__item--m--3"> [optional]<div class="grid__inner"></div>[/optional] </div> <div class="grid__item--12 grid__item--s--6"> [optional]<div class="grid__inner"></div>[/optional] </div> <div class="grid__item--12 grid__item--s--6"> [optional]<div class="grid__inner"></div>[/optional] </div> </div> */
/*------------------------------------*  $BASE
\*------------------------------------*/
/** Styles for all devices */
@media (max-width: 719px) { .grid { margin-left: -1em; } }

[class*='grid__item'] { float: left; width: 100%; }

@media (max-width: 719px) { [class*='grid__item'] { padding-left: 1em; } }

.grid__item--1 { width: 8.3333333%; }

.grid__item--2 { width: 16.6666667%; }

.grid__item--3 { width: 25%; }

.grid__item--4 { width: 33.3333333%; }

.grid__item--5 { width: 41.6666667%; }

.grid__item--6 { width: 50%; }

.grid__item--7 { width: 58.3333333%; }

.grid__item--8 { width: 66.6666667%; }

.grid__item--9 { width: 75%; }

.grid__item--10 { width: 83.3333333%; }

.grid__item--11 { width: 91.6666667%; }

.grid__item--12 { width: 100%; }

/*------------------------------------*    $MEDIUM \*------------------------------------*/
@media (min-width: 720px) { .grid { margin-left: -1.25em; }
  [class*='grid__item'] { padding-left: 1.25em; }
  .grid__item--m--1 { width: 8.3333333%; }
  .grid__item--m--2 { width: 16.6666667%; }
  .grid__item--m--3 { width: 25%; }
  .grid__item--m--4 { width: 33.3333333%; }
  .grid__item--m--5 { width: 41.6666667%; }
  .grid__item--m--6 { width: 50%; }
  .grid__item--m--7 { width: 58.3333333%; }
  .grid__item--m--8 { width: 66.6666667%; }
  .grid__item--m--9 { width: 75%; }
  .grid__item--m--10 { width: 83.3333333%; }
  .grid__item--m--11 { width: 91.6666667%; }
  .grid__item--m--12 { width: 100%; } }

/*------------------------------------*    $LARGE \*------------------------------------*/
@media (min-width: 960px) { .grid { margin-left: -1.25em; }
  [class*='grid__item'] { padding-left: 1.25em; }
  .grid__item--l--1 { width: 8.3333333%; }
  .grid__item--l--2 { width: 16.6666667%; }
  .grid__item--l--3 { width: 25%; }
  .grid__item--l--4 { width: 33.3333333%; }
  .grid__item--l--5 { width: 41.6666667%; }
  .grid__item--l--6 { width: 50%; }
  .grid__item--l--7 { width: 58.3333333%; }
  .grid__item--l--8 { width: 66.6666667%; }
  .grid__item--l--9 { width: 75%; }
  .grid__item--l--10 { width: 83.3333333%; }
  .grid__item--l--11 { width: 91.6666667%; }
  .grid__item--l--12 { width: 100%; } }

/*------------------------------------*  BUTTON
\*------------------------------------*/
/** CONTENTS BASE................ SIZES............... FONT-SIZES.......... FUNCTIONS........... */
/*------------------------------------*  $BASE
\*------------------------------------*/
.button { display: inline-block; margin: 0; padding: 0.5em 1em; border: none; background: #f1840f; vertical-align: top; white-space: nowrap; font-size: 100%; font-family: inherit; cursor: pointer; }

.button, .button:hover, .button:focus, .button:active, .button:visited { outline: none; color: #fff; text-decoration: none; }

.button:hover, .button:focus, .button:active { background: #f5a957; }

/*------------------------------------*  $SIZES
\*------------------------------------*/
/** Button size modifiers. These all follow the same sizing rules as above; text is 1em, space around it remains uniform. */
/*------------------------------------*  $FONT-SIZES
\*------------------------------------*/
/** Button font-size modifiers. */
/*------------------------------------*  $FUNCTIONS
\*------------------------------------*/
/*------------------------------------*  MENU
\*------------------------------------*/
/** CONTENTS DEFAULT............. OFF CANVAS.......... HORIZONTAL.......... VERTICAL............ */
/*------------------------------------*  $DEFAULT
\*------------------------------------*/
/** 1. Set position root for off canvas menu 2. Set position root for sub menus (dropdowns) */
html { position: relative; /* [1] */ }

.menu__list { margin-bottom: 0; }

.menu__list--level1 { transition: transform 0.2s; }

.menu__item { position: relative; /* [2] */ }

.menu__link { display: block; text-decoration: none; }

.menu__checkbox { display: none; }

@media (min-width: 720px) { .menu__controls { display: none; } }

/*------------------------------------*  $OFF CANVAS
\*------------------------------------*/
.menu--off-canvas .menu__controls { position: absolute; top: 0; left: 0; z-index: 1; display: block; width: 100%; }

.menu--off-canvas .menu__list--level1 { position: absolute; top: 0; bottom: 0; left: 0; padding-top: 3em; max-width: 20em; width: 70%; transform: translate(-100%, 0); }

.menu--off-canvas .menu__checkbox:checked + .menu__list--level1 { transform: translate(0, 0); }

/*------------------------------------*  $HORIZONTAL
\*------------------------------------*/
@media (max-width: 719px) { .menu--horizontal .menu__list--level1 { height: 0; transform: scale(1, 0); transform-origin: 0 0; }
  .menu--horizontal .menu__checkbox:checked + .menu__list--level1 { height: auto; transform: scale(1, 1); } }

@media (min-width: 720px) { .menu--horizontal .menu__list--level1 .menu__list { position: absolute; visibility: hidden; opacity: 0; transition: opacity 0.2s; }
  .menu--horizontal .menu__list--level1 .menu__list .menu__list { top: 0; left: 100%; }
  .menu--horizontal .menu__item:hover > .menu__list, .menu--horizontal .menu__item:focus > .menu__list { visibility: visible; opacity: 1; }
  .menu--horizontal .menu__item--level1 { float: left; } }

/*------------------------------------*  $VERTICAL
\*------------------------------------*/
/*------------------------------------*  MEDIA
\*------------------------------------*/
/** CONTENTS BASE................ Place any image- and text-like content side-by-side, as per: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/ <div class="media"> <a href="#" class="media__img"> <img src="pony.png"> </a> [optional] <div class="media__img--rev"> <img src="pony2.png"> </div> [/optional] <div class="media__body"> <p>Lorem Ipsum</p> </div> </div> */
/*------------------------------------*  $BASE
\*------------------------------------*/
/** Reset media styling on small screens */
@media (max-width: 719px) { .media--mobile-reset .media__img { float: none; margin-right: 0; }
  .media--mobile-reset .media__img--rev { float: none; margin-left: 0; } }

.media__img { float: left; margin-right: 1.25em; }

/** Reversed image location (right instead of left). */
.media__img--rev { float: right; margin-left: 1.25em; }

.media__img img, .media__img--rev img { display: block; }

.media__body { overflow: hidden; }

/*------------------------------------*  SCAFFOLDING
\*------------------------------------*/
/** CONTENTS SYSTEM.............. BASE................ COMPONENTS.......... */
/*------------------------------------*  $SYSTEM
\*------------------------------------*/
/*------------------------------------*  $BASE
\*------------------------------------*/
/*------------------------------------*  Typography
\*------------------------------------*/
/** CONTENTS VISIBILITY.......... */
body { font-family: "Noto Sans", sans-serif; color: #7e8186; font-size: 14px; }

h1, .event .field-title-field, .event .field-event-date, h2, h3, h4, h5, h6 { font-family: "Noto Serif", sans-serif; color: #3c3f46; text-transform: none; }

/*------------------------------------*  UTILITY
\*------------------------------------*/
/** CONTENTS VISIBILITY.......... */
/*------------------------------------*  $VISIBILITY
\*------------------------------------*/
/** Hide only visually, but have it available for screenreaders: h5bp.com/v */
.u-text-align--right { text-align: right !important; }

.u-display--flex { display: flex; }

.u-align-items--center { align-items: center; }

/*------------------------------------*  FORM
\*------------------------------------*/
/** CONTENTS BASE................ DATE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
@media (min-width: 640px) { #user-register-form { width: 50%; } }

.form__item { margin-bottom: 1em; }

.form__item input, .form__item select { border: 1px solid #ccc !important; }

.form__item .form__item { margin-bottom: 0; }

.form__wrapper { margin-bottom: 0; }

.form__label, .form__fieldset-legend { font-weight: bold; font-size: 1em; }

/*------------------------------------*  $DATE
\*------------------------------------*/
.form__item--date-select .form__item { display: inline-block; margin-right: 0.5em; margin-bottom: 0; }

.form__item--date-select .form__label { font-weight: 400; }

/*------------------------------------*  $COMPONENTS
\*------------------------------------*/
.inner-container { position: relative; z-index: 10; display: flex; justify-content: space-between; align-items: center; }

/*------------------------------------*  Button
\*------------------------------------*/
.button { padding: 0.7em 1.3em; background: #f1840f; color: #ffffff; text-transform: uppercase; }

.event .event-top-label { color: #f1840f; margin-bottom: 5px; }

.event .field-title-field { margin-bottom: 0; }

.event .field-event-date { font-family: "Noto Sans", sans-serif; font-weight: 100; }

.event .field-body { color: black; margin-top: 20px; }

.event .field-body ul { padding: 0; list-style-type: none; }

.event .field-body ul li { font-weight: bold; }

.event .field-body ul li:before { content: "\2014"; padding-right: 1em; }

.event .field-programm-als-pdf { padding: 1.2em; border: 1px solid #f1840f; display: inline-block; margin-bottom: 2em; }

.event .field-programm-als-pdf a { color: #7e8186; }

.event .event-file-btn { background: none; padding: 1em 2em; border: 5px solid #f1840f; margin-bottom: 2em; margin-top: 20px; font-family: 'Noto Serif', sans-serif; text-transform: none; font-size: 17px; }

.event .event-file-btn:before { content: "\f133"; display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; padding: 1em; border-radius: 2em; background-color: #f1840f; color: #fff; }

.event .event-file-btn a { color: black; margin-left: 10px; padding: 0 3px 4px; border-bottom: 1px solid #7e8186; }

.event .event-file-btn a:hover { text-decoration: none; }

.event .event-file-btn a:after { content: "\f019"; display: inline-block; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; margin-left: 15px; }

.event .event-details { border: 5px solid #f1840f; margin-bottom: 2em; text-align: right; color: black; }

.event .event-details h3 { text-align: left; border-bottom: 1px solid #f1840f; padding: 10px 1em; margin-bottom: 25px; color: black !important; font-family: 'Noto Serif', sans-serif; font-weight: normal; }

.event .event-details .event-details-header { color: #f1840f; font-weight: bold; padding: 0 1em; }

.event .event-details .event-details-header .event-taxdetails { color: black; font-size: 0.8em; }

.event .event-details .event-details-price-type { padding: 0 1em; }

.event .event-details .event-details-date { margin: 0 0 20px; padding: 0 1em; font-weight: bold; }

.event .event-details .event-details-price { padding: 0 1em; font-size: 14px; margin-bottom: 5px; }

.event .event-details .event-details-price.netto { margin-bottom: 20px; font-weight: bold; }

.event .event-details .event-details-addtocart { padding: 0 1em; }

.event .event-details .event-storno { padding: 0 1em 1em; line-height: 20px; }

.event .event-details .headline-border { width: 100%; height: 1px; border: #f1840f; }

.event .event-details .field-location-address { padding: 0 1em; margin-bottom: 20px; }

.event .event-details .cancellation-policy { color: grey; margin: 15px; font-size: 13px; display: inline-block; }

.event .event-details .cancellation-policy:before { content: "*"; padding-right: 2px; display: inline-block; font-size: 0.8em; vertical-align: super; }

.event h1, .event .field-title-field, .event .field-event-date { margin-bottom: 0; font-weight: normal; }

.event h3 { font-family: "Noto Sans", sans-serif; color: #f1840f !important; }

.event h3.c-program { margin-top: 30px; }

.field-event-type { font-weight: bold; color: #f1840f; }

.view--events--page h1, .view--events--page .event .field-title-field, .event .view--events--page .field-title-field, .view--events--page .event .field-event-date, .event .view--events--page .field-event-date { margin-bottom: 1.5em; }

.view--events--page .view__row { padding-bottom: 2em; }

.view--events--page .view__row:not(:first-child) { padding-top: 2em; }

.view--events--page .view__row:not(:last-child) { border-bottom: 1px solid #ccc; }

.view--events--page .view__row .field-event-date { font-weight: bold; color: #f1840f; }

.checkout-help { margin-bottom: 2em; font-weight: bold; }

.cart_contents, .checkout-completion-message, .checkout_review { padding: 2em; /* border-radius: 20px; */ background: #f3f3f3; margin-bottom: 2em; }

.cart_contents .form__fieldset-legend, .checkout-completion-message .form__fieldset-legend, .checkout_review .form__fieldset-legend { display: none; }

.checkout_review .pane-title { font-weight: bold; display: block; padding-top: 2em; }

.views-field-line-item-title { font-size: 1.2em; font-weight: bold; color: #000; }

.view--commerce-user-orders table, .view--order-coupon-list table { width: 100%; margin-top: 0.5em; }

.view--commerce-user-orders table td, .view--commerce-user-orders table th, .view--order-coupon-list table td, .view--order-coupon-list table th { border: 1px solid #ccc; text-align: left; background: white; padding: 0.5em; }

.view--commerce-user-orders table ul, .view--order-coupon-list table ul { padding: 0; }

.view--commerce-user-orders table ul li, .view--order-coupon-list table ul li { list-style-type: none; }

.commerce-order-commerce-order .views-table, .view--commerce-cart-form--default__content .views-table { width: 100%; }

.commerce-order-commerce-order .views-field-line-item-title, .view--commerce-cart-form--default__content .views-field-line-item-title { font-size: 1rem; }

.commerce-order-commerce-order th, .view--commerce-cart-form--default__content th { background: #f3f3f3; padding: 1em; font-size: 1rem; font-weight: normal; text-align: left; color: black; font-weight: bold; text-align: right; }

.commerce-order-commerce-order td, .view--commerce-cart-form--default__content td { padding: 1rem; border: 1px solid #ccc; text-align: right; }

.commerce-order-commerce-order td:first-child, .commerce-order-commerce-order th:first-child, .view--commerce-cart-form--default__content td:first-child, .view--commerce-cart-form--default__content th:first-child { text-align: left; }

.commerce-price-formatted-components { text-align: right; float: right; }

#commerce-checkout-coupon-ajax-wrapper, #commerce-checkout-form-checkout .group-additional, .form__field--name-commerce-customer-address, .form__field--name-field-alternate-invoice-address { padding: 2em; background: #f3f3f3; }

#commerce-checkout-coupon-ajax-wrapper h3, #commerce-checkout-form-checkout h3 { font-weight: bold; font-size: 1em; font-family: "Noto Sans", sans-serif; color: #7e8186; }

#commerce-checkout-coupon-ajax-wrapper fieldset, #commerce-checkout-form-checkout fieldset { margin-bottom: 2em; }

.checkout-review h3 { font-weight: bold; font-size: 1em; font-family: "Noto Sans", sans-serif; color: #7e8186; }

.checkout-review .field-checkbox-alt-invoice, .checkout-review .field-alternate-invoice-address, .checkout-review .field-cancellation-policy, .checkout-review .field-book-hotel, .checkout-review .field-amic-member { display: none; }

.checkout-continue { float: right; }

.button-operator { display: none; }

.event-details-addtocart .button.form__submit { width: 100%; font-weight: bold; }

.component-type-commerce-price-formatted-amount td.component-title { font-weight: bold; padding-right: 10px; }

.component-type-commerce-price-formatted-amount td.component-total { font-size: 2em; }

.commerce-line-item-actions { display: flex; justify-content: space-between; }

.line-item-summary { text-align: right; font-size: 1.5em; margin-bottom: 1em; }

.form__item--customer-profile-billing-field-checkbox-alt-invoice-und { margin-top: 2em; }

.view--events--attachment-1__content { display: flex; border-bottom: 1px solid #ccc; border-top: 1px solid #ccc; }

.block-views-exp-events-page .views-exposed-widgets { display: flex; justify-content: space-between; background: #f3f3f3; width: 100%; padding: 1.5em; align-items: center; }

.block-views-exp-events-page input, .block-views-exp-events-page select { width: auto; }

div.tab { background: none; color: #222; display: inline-block; padding: 10px 15px; cursor: pointer; }

div.tab.current { color: #fff; background: #f1840f; }

div.tab-content { display: none; padding: 15px; }

div.tab-content .date-display-single { display: none; }

div.tab-content.current { display: inherit; }

input#edit-event-code { border: 1px solid #f1840f; text-align: center; font-family: "Noto Sans", sans-serif; font-size: 12px; }

.group-hosts-image { width: 120px; margin-right: 20px; float: left; }

.group-hosts-detail { height: 80px; }

.field--node-hosts--title-field { font-size: 1.3rem !important; color: black !important; font-family: "Noto Sans", sans-serif !important; font-weight: bold !important; }

.c-hosts-list { margin: 50px 0 60px; }

.c-hosts-list .links.inline { display: none; }

.c-hosts-list article { /*margin-bottom: 20px;*/ clear: both; overflow: auto; }

.c-hosts-list article header { display: none; }

.c-hosts-list article header h2 { font-family: "Noto Sans", sans-serif; margin-bottom: 0; }

.field-host-profile { color: black; }

.field-time { width: 15%; float: left; font-size: 1.3rem; font-weight: bold; }

.field-event-plan { width: 85%; float: left; margin-bottom: 20px; }

.field-event-program { color: black; }

.field-event-location { height: 250px; }

.field-event-location > div { height: 250px !important; }

#event-storno h3 { font-family: "Noto Sans", sans-serif; font-size: 1.5rem; color: #f1840f; }

#event-storno .event-storno { line-height: 20px; color: black; }

#event-storno .event-storno small b { font-weight: normal; }

#rm--mobile-month-selector { display: none; }

@media (max-width: 719px) { #rm--mobile-month-selector { display: block; }
  .view--events--page .view__attachment { display: none; }
  .block-views-exp-events-page .views-exposed-widgets { display: block; }
  .view--events--page__content article > .u-display--flex { display: block; }
  .view--events--page__content article > .u-display--flex > div { width: 100%; } }

.testimonial h3 { margin-bottom: 1em; }

.testimonial .field-testimonial-image img { border-radius: 160px; }

.testimonial h4 { margin-top: 1em; }

.testimonial h4:before { content: "\f10d"; display: inline-block; font: normal normal normal 3rem/1 FontAwesome; font-size: 2em; color: #a9a9a9; text-rendering: auto; -webkit-font-smoothing: antialiased; }

/*------------------------------------*  Breadcrumbs
\*------------------------------------*/
.easy-breadcrumb { padding: 4em 0; color: #ffffff; }

.easy-breadcrumb a { color: #ffffff; }

#topnavigation { position: relative; background-color: #f1840f; overflow: auto; line-height: 1em; padding: 1.2em 0; }

#topnavigation .login-link { display: inline-block; margin-left: 2em; }

#topnavigation .login-link a { color: #fff; }

#topnavigation #region--topnavigation { width: 100%; }

#topnavigation .afs-academy-top-menu { float: right; display: inline-block; color: #ffffff; }

#topnavigation .afs-academy-top-menu.left { float: none; }

#topnavigation .afs-academy-top-menu ul.menu__list { padding: 0; display: flex; }

#topnavigation .afs-academy-top-menu ul.menu__list li { list-style: none; display: block; float: left; text-transform: uppercase; padding: 0 1em; }

#topnavigation .afs-academy-top-menu ul.menu__list li:not(:last-child) { border-right: 1px solid #fff; }

#topnavigation .afs-academy-top-menu ul.menu__list li.shoppingcart { overflow: hidden; }

.not-logged-in #topnavigation .afs-academy-top-menu ul.menu__list li.shoppingcart { display: none; }

#topnavigation .afs-academy-top-menu ul.menu__list li.shoppingcart a { text-indent: -9999px; width: 20px; }

#topnavigation .afs-academy-top-menu ul.menu__list li.shoppingcart a::before { content: "\f07a"; font-family: fontawesome; /* add icon back in to view */ display: inline-block; position: absolute; text-indent: 9999px; }

#topnavigation .afs-academy-top-menu ul.menu__list li a { color: #ffffff; }

#topnavigation .afs-academy-top-menu ul.menu__list li a.outgoing-link { text-transform: none; }

#topnavigation .afs-academy-top-menu ul.menu__list li a.outgoing-link:after { content: "\f08e"; display: inline-block; position: relative; font: normal normal normal 14px/1 FontAwesome; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; padding-left: 4px; }

.block--user-menu { display: inline-block; }

.block--user-menu li { margin: 0 1em 0 0; display: inline-block; }

.block--user-menu a { display: inline-block; text-transform: uppercase; color: white; }

.academy-block { background: #f5f5f5; padding: 3em 0; }

.academy-block .side-graphics { display: flex; align-items: center; justify-content: center; width: 60px; height: 60px; border-radius: 50px; margin-top: 5px; }

.domain-afs-academy-at .academy-block .side-graphics { background: #f1840f; }

.domain-afs-medical-at .academy-block .side-graphics { background: #f1840f; }

.academy-block .fa { background: #089bd9; color: white; padding: 0.6em; border-radius: 2em; margin-top: 3px; }

.academy-block h2, .academy-block h4 { font-weight: 100; }

.academy-block h2 { font-size: 2rem; margin-top: 1em !important; margin-bottom: 1em !important; }

.academy-block .col3 { margin-top: 1em; }

.academy-block .academy-block-app { margin-top: 4em; }

@media (max-width: 625px) { .academy-block .academy-block-app a { float: none !important; margin-bottom: 1em; display: inline-block; } }

.membership { text-align: center; }

.membership h4 { font-size: 1em; margin: 1.5em 0 1em 0; }

#block--language ul { padding-left: 0; margin: 0; float: left; }

#block--language ul li { list-style-type: none; display: inline-block; margin: 0; }

#block--language ul li a { color: #ffffff; padding-right: 5px; }

#block--language ul li:not(.first) a { border-left: 1px solid #f5f5f5; padding-left: 6px; }

.logos-outer { display: flex; align-items: center; justify-content: center; min-height: 157px; }

/*------------------------------------*  SKIPLINKS
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.skiplinks__list { margin: 0; padding: 0; height: 0; }

.skiplinks__skiplink.u-focusable:active, .skiplinks__skiplink.u-focusable:focus { position: fixed; left: 50%; z-index: 9999; margin-left: -7em; padding: 0.25em 0 0.35em 0; width: 14em; height: auto; outline: 0; background: #f1840f; box-shadow: 0 0 0.75em #666; color: #7e8186; text-align: center; }

/*------------------------------------*  Paragraphs
\*------------------------------------*/
.view--display-event-front .view__field--title { text-overflow: ellipsis-word; height: 90px; }

.view--display-event-front .view__field--title, .view--display-event-front .field--node-event--body { background-color: rgba(195, 109, 37, 0.6); padding: 0.3em; }

.view--display-event-front .view__field--title p, .view--display-event-front .field--node-event--body p { margin-bottom: 0; min-height: 173px; }

.paragraphs-item-thirds-cards { margin-bottom: 8rem; color: #ffffff; }

.paragraphs-item-thirds-cards .card { padding-left: 0 !important; position: relative; }

.paragraphs-item-thirds-cards .card > div { padding: 3em 3em 0 3em; min-height: 350px; text-align: center; }

.paragraphs-item-thirds-cards .card > div h2 { font-weight: 100; margin-bottom: 1em; margin-top: 1em; color: #ffffff; }

.paragraphs-item-thirds-cards .card > div h2:after { display: block; content: "\00a0"; width: 50px; height: 18px; margin: 0 auto; border-bottom: 2px solid #f1840f; }

.paragraphs-item-thirds-cards .left.icon { position: absolute; bottom: -50px; margin-left: auto; margin-right: auto; left: 0; right: 0; z-index: 200; width: fit-content; display: inline-block; }

.paragraphs-item-thirds-cards .field-text-mid { color: #ffffff; }

.paragraphs-item-thirds-cards .field-text-mid h2 { color: #ffffff; }

.paragraphs-item-thirds-cards .field-text-mid h2:after { border-bottom: 2px solid #f1840f !important; }

.paragraphs-item-thirds-cards .middle.icon { position: absolute; bottom: -50px; margin-left: auto; margin-right: auto; left: 0; right: 0; z-index: 200; width: fit-content; display: inline-block; }

.paragraphs-item-thirds-cards .right.icon { position: absolute; bottom: -50px; margin-left: auto; margin-right: auto; left: 0; right: 0; z-index: 200; width: fit-content; display: inline-block; }

.card:hover > div { background-color: rgba(241, 132, 15, 0.8); }

@media (max-width: 719px) { .paragraphs-item-thirds-fields .grid__item--12 { max-width: 390px; } }

.paragraphs-item-thirds-fields .background { background-size: cover; text-align: center; }

.paragraphs-item-thirds-fields .background a { margin: 12em auto; display: inline-block; }

/*------------------------------------*  PAGER
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.pager { text-align: center; }

.pager a { text-decoration: none; }

.pager .pager__item { display: inline-block; margin: 0 0.25em; }

.pager .pager__item--current { font-weight: 700; }

/*------------------------------------*  Page
\*------------------------------------*/
/** CONTENTS BASE................ */
.front article header { display: none; }

.not-front #title--main { display: none; }

/*------------------------------------*  MESSAGE
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.message { margin-bottom: 1em; padding: 1.25em; border-left: 5px solid #3A87AD; background: #e1edf3; color: #3A87AD; }

.message ul { margin: 0; padding-left: 1.5em; }

.message--error { border-color: #b94a48; background: #f5e4e4; color: #b94a48; }

.message--warning { border-color: #c09853; background: #f6f0e5; color: #c09853; }

/*------------------------------------*  CKEDITOR
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.cke_show_borders { margin: 1em; }

/*------------------------------------*  MENU
\*------------------------------------*/
/** CONTENTS HORIZONTAL.......... VERTICAL............ */
/*------------------------------------*  $HORIZONTAL
\*------------------------------------*/
@media (max-width: 719px) { .menu--horizontal { align-self: flex-start; flex: 1; }
  .menu--horizontal .menu__item { background-color: #003866; }
  .menu--horizontal .menu__item .menu__link { padding: 1em; color: #fff; border-bottom: 1px solid #089bd9; }
  .menu--horizontal .menu__item .menu__link:focus, .menu--horizontal .menu__item .menu__link:hover { text-decoration: none; }
  .menu--horizontal .menu__item .menu__link--level2 { padding-left: 2.5em; }
  .menu--horizontal .menu__item .menu__link--level1 { text-transform: uppercase; } }

@media (min-width: 720px) { .menu--horizontal { display: flex; align-items: center; justify-content: center; }
  .menu--horizontal .menu__list--level1 { align-self: flex-start; flex: 1; } }

@media (min-width: 720px) and (max-width: 1025px) { .menu--horizontal .menu__list--level1 { display: flex; justify-content: space-around; margin-top: 2em; } }

@media (min-width: 720px) { .menu--horizontal .menu__list--level1 .menu__link--level1 { font-family: "Noto Serif", sans-serif; font-size: 1.1em; }
  .menu--horizontal .menu__list--level1 li { padding-right: 1.5em; }
  .menu--horizontal .menu__list--level1 li a { color: #7e8186; }
  .menu--horizontal .menu__list--level1 li ul { width: 250px; left: -5em; }
  .menu--horizontal .menu__list--level1 li ul li { background-color: #003866; border-bottom: 1px solid #089bd9; transition: 0.1s, background-color; }
  .menu--horizontal .menu__list--level1 li ul li a { padding: 1em; color: #fff; }
  .menu--horizontal .menu__list--level1 li ul li a:hover { text-decoration: none; }
  .menu--horizontal .menu__list--level1 li ul li:hover { background-color: #089bd9; }
  .menu--horizontal .menu__list--level1 li ul li:last-child { border: none; }
  .menu--horizontal .menu__list--level1 li ul:before { content: ""; display: block; width: 0; height: 0; margin-left: 8em; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid #003866; } }

@media (min-width: 720px) and (max-width: 83em) { .menu--horizontal .menu__list--level1 li.menu__item--1033 ul { left: 0; }
  .menu--horizontal .menu__list--level1 li.menu__item--1033 ul:before { margin-left: 2.5em; } }

/*------------------------------------*  $VERTICAL
\*------------------------------------*/
#cmp--footer { background-color: #f1840f; }

/*------------------------------------*  TABS
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
@media (min-width: 480px) { .tabs li { float: left; } }

.tabs a { display: block; padding: 0.5em 0.75em; border-bottom: 1px solid #a9a9a9; color: #7e8186; text-decoration: none; }

.tabs .is-active { border: 1px solid #a9a9a9; border-bottom: none; }

/*------------------------------------*  FLEXSLIDER
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
/* Browser Resets
*********************************/
.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus { outline: none; }

.slides, .flex-control-nav, .flex-direction-nav { margin: 0; padding: 0; list-style: none; }

/* FlexSlider Necessary Styles
*********************************/
.flexslider { margin: 0; padding: 0; }

.flexslider .slides > li { display: none; -webkit-backface-visibility: hidden; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img { display: block; margin-left: auto; margin-right: auto; height: auto; }

.flex-pauseplay span { text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after { display: block; visibility: hidden; clear: both; height: 0; content: '\0020'; line-height: 0; }

html[xmlns] .slides { display: block; }

* html .slides { height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you include js that eliminates this class on page load */
.no-js .slides > li:first-child { display: block; }

.flexslider { position: relative; overflow: hidden; }

.flexslider:hover .flex-prev, .flexslider:hover .flex-next { opacity: 0.6; }

.flexslider .view__row { position: relative; }

.flexslider .slides > li { position: relative; }

.flex-control-paging a { cursor: pointer; }

/** Controls */
.flex-prev, .flex-next { color: transparent !important; position: absolute; top: 0; display: flex; align-items: center; padding: 0 1.25em; height: 100%; text-decoration: none; font: normal normal normal 14px/1 FontAwesome; text-rendering: auto; -webkit-font-smoothing: antialiased; }

.flex-prev .fa, .flex-next .fa { position: relative; top: 45%; }

.flex-prev:before { color: #a9a9a9 !important; content: "\f104"; font-size: 3em; opacity: .6; }

.flex-next { right: 0; }

.flex-next:after { color: #a9a9a9 !important; content: "\f105"; font-size: 3em; opacity: .6; }

.flex-disabled { display: none; }

.c-hero--align-left { text-align: left; }

.c-hero { position: relative; height: 100vh; text-align: center; color: #fff; background-size: cover; }

@media (min-width: 45em) { .c-hero { height: 33.375em; } }

@media (min-width: 88em) { .c-hero { height: 44em; } }

#flexslider-1 .c-hero { background-position-x: right; }

#flexslider-1 .flex-outer { height: 100%; }

#flexslider-1 .c-slideshow__caption { display: flex; align-items: center; justify-content: flex-start; float: left; height: 100%; }

#flexslider-1 .c-slideshow__caption .flex-container { width: 65%; }

#flexslider-1 .c-slideshow__caption .flex-container h1, #flexslider-1 .c-slideshow__caption .flex-container .event .field-title-field, .event #flexslider-1 .c-slideshow__caption .flex-container .field-title-field, #flexslider-1 .c-slideshow__caption .flex-container .event .field-event-date, .event #flexslider-1 .c-slideshow__caption .flex-container .field-event-date { color: #ffffff; font-weight: normal; font-size: 3.5em; }

#flexslider-1 .c-slideshow__caption .flex-container .field-slideshow-textarea { font-size: 1.1em; }

#flexslider-1 .c-slideshow__caption .flex-container .field-slideshow-textarea a { color: #fff; }

#carousel.flex1 .slides li { margin: 10px 10px 10px 0; border: 1px solid #089bd9; height: 110px; width: 110px; align-items: center; display: flex !important; }

#carousel.flex1 .slides img { padding: 5px; }

.flex1 ul.slides { display: flex; align-items: center; align-content: center; }

.field-teaser-image { display: flex; align-items: center; align-content: center; height: 177px; overflow: hidden; }

/*------------------------------------*  GRID-SPACING
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.grid-spacing { margin-top: -1em; }

.grid-spacing.grid--condensed { margin-top: -0.875em; }

.grid-spacing__item { margin-top: 1em; }

.grid--condensed .grid-spacing__item { margin-top: 0.875em; }

@media only screen and (min-width: 480px) { .grid-spacing { margin-top: -1em; }
  .grid-spacing__item { margin-top: 1em; } }

@media only screen and (min-width: 720px) { .grid-spacing { margin-top: -1.25em; }
  .grid-spacing__item { margin-top: 1.25em; } }

@media only screen and (min-width: 960px) { .grid-spacing { margin-top: -1.25em; }
  .grid-spacing__item { margin-top: 1.25em; } }

/*------------------------------------*  ROW-STYLE
\*------------------------------------*/
/** CONTENTS BASE................ */
/*------------------------------------*  $BASE
\*------------------------------------*/
.row-style__item:not(:last-child) { margin-bottom: 2em; padding-bottom: 2em; border-bottom: 1px dotted #a9a9a9; }

.row-style--condensed .row-style__item:not(:last-child) { margin-bottom: 1em; padding-bottom: 1em; }

.top-margin { margin-top: 3em; }

.center { text-align: center; }

.node-type--location .cmp--hero { display: none; }

.node-type--location .cmp--content { margin-top: 0; }

.field-location-geolocation, .field-location-location { margin-bottom: 2em; }

.organisation-name { font-weight: 700; margin-bottom: 1em; display: block; }

.field-location-address { margin-bottom: 1em; }

#cmp--content { margin-top: 0; }

body { margin-top: 10px; }

.skiplinks, #block--2, #block--3, #cmp--footer, #cmp--header { display: none !important; }

.event-file-btn { display: none !important; }

article.event .grid__item--m--9, .field-event-location { display: none; }

body { padding-top: 6rem; }

.cartbar { position: fixed; top: 0; width: 100%; background: #333; color: white; height: 5rem; padding: 1rem; }

.cartbar a { color: white; }

.cartbar .cartlink { float: left; }

.cartbar .totalamount { float: right; font-size: 1.75rem; }

body.page-cart { padding-top: 0; }

.page-cart .cartbar { display: none; }

/*------------------------------------*  STYLE
\*------------------------------------*/
/** CONTENTS TOOLS............... HEADER.............. HERO................ CONTENT............. TEASER.............. SOCIAL.............. NEWSLETTER.......... */
/*------------------------------------*  $TOOLS
\*------------------------------------*/
select, input[type="text"], input[type="email"], input[type="password"], textarea { border: none; border-bottom: 2px solid #b2b2b2; width: 100%; background: #fff; }

input:focus, input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, select:focus, textarea:focus, button:focus { outline: none; box-shadow: none; }

.container--s { max-width: 57em; }

.container--l { max-width: 86em; }

.colorbox:focus { outline: none; }

.septem-menu { z-index: 101; }

.intro { font-size: 1.3rem; }

.intro p { margin-bottom: 1.5rem; }

.font-b-family, .font-b-family a { font-family: "Noto Sans", sans-serif; }

.uppercase { text-transform: uppercase; }

/** Responsiv images */
img { width: auto; max-width: 100%; height: auto; }

.gmap img, .field--type-geolocation-latlng img { max-width: none; }

@media (max-width: 719px) { .media .media__img { float: none; margin: 0; margin-bottom: 1.25em; } }

/** Embedd Container */
.embed-container { position: relative; overflow: hidden; padding-bottom: 90%; max-width: 100%; height: 0; height: auto; }

.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.embed-container--youtube { padding-bottom: 56.25%; }

/*------------------------------------*  $HEADER
\*------------------------------------*/
.cmp--header { border-bottom: 1px solid rgba(23, 23, 23, 0.26); }

.not-front .cmp--header { border-bottom: 0; }

@media (max-width: 1024px) { .cmp--header { border-bottom: 0; } }

.headroom { position: fixed; z-index: 100; width: 100%; transition: background 0.3s 0.3s; animation-duration: 0.3s; }

.headroom.headroom--not-top { background: rgba(34, 34, 34, 0.4); }

.logo--main, .region--navigation { display: inline-block; /* flex-box fallback */ }

@media (max-width: 719px) { .logo--main { display: block; width: 100%; margin-top: 1.5em; margin-bottom: 2.5em; max-width: 300px; }
  .logo--main img { width: 100%; }
  .menu__controls { text-align: right; } }

.nav-bar { padding: 1em 0; }

@media (max-width: 720px) { .logo__link { position: absolute; top: 20px; left: 0; right: 0; text-align: center; } }

.logo--main__img { height: 34px; transition: width 0.3s 0.3s; }

@media (min-width: 720px) { .logo--main__img { width: 300px; height: 56px; max-width: none; position: relative; } }

@media (min-width: 1100px) { .logo--main__img { width: 360px; height: 67px; } }

.region--navigation { width: 100%; /* flex-box fallback */ vertical-align: middle; /*********************/ }

h1, .event .field-title-field, .event .field-event-date, h2, h3, h4, h5, h6 { color: #3c3f46; }

/*------------------------------------*  $HERO
\*------------------------------------*/
@media (max-width: 719px) { .cmp--hero { display: none; } }

.cmp--hero .flex-caption { position: absolute; z-index: 1; bottom: 3em; width: 100%; }

.cmp--hero .flex-caption .inner { font-size: 1.3rem; max-width: 20em; }

.cmp--hero .flex-caption .flex-prev:hover, .cmp--hero .flex-caption .flex-next:hover { opacity: 1; }

.cmp--hero .flex-caption .flex-direction-nav { position: absolute; top: 50%; left: 0; right: 0; }

.cmp--hero .flex-caption .flex-direction-nav i { color: #fff; font-size: 4em; }

.cmp--hero .flex-caption .flex-direction-nav a { height: auto; top: auto; bottom: 2em; }

.cmp--hero .flex-control-nav { width: 100%; position: absolute; height: auto; padding: 2px 0; text-align: center; }

.cmp--hero .flex-control-nav li, .cmp--hero .flex-control-nav a { display: inline-block; }

.cmp--hero .flex-control-nav a { margin: 0 0.2em; width: 0.8em; height: 0.8em; background: #f1840f; border-radius: 100%; text-decoration: none; text-indent: -999em; cursor: pointer; line-height: 71%; }

.cmp--hero .flex-control-nav .flex-active { background: #f1840f; }

/*------------------------------------*  $CONTENT
\*------------------------------------*/
.cmp--content { margin-top: 8em; padding-bottom: 2em; }

@media (max-width: 1024px) { .cmp--content { margin-top: 3em; } }

.front .cmp--content { margin-top: 0; padding-bottom: 10px; }

.front .cmp--content .field--node-page--title-field { display: none; }

/** Page */
.field--field-page-image { margin-bottom: 1.5em; }

/** News */
.field--field-news-date { position: relative; top: -0.75em; }

.field--field-news-image { margin-bottom: 1.5em; }

/** Location */
.field--field-location-contact-form { margin-top: 1.25em; }

/* Paragraphs */
.entity-paragraphs-item p:last-child { margin-bottom: 0; }

.paragraphs-item-intro { font-size: 1.2em; }

.field--paragraphs_item-header_image--field-paragraph-image, .paragraphs-item-intro, .field-paragraph-textarea, .paragraphs-item-50-50 img, .paragraphs-item-50-50 .field--type-text-long, .paragraphs-item-33-33-33 img, .paragraphs-item-33-33-33 .field--type-text-long, .paragraphs-item-gallery, .paragraphs-item-image-left-text-float { margin-bottom: 1.25em; }

.paragraphs-item-image-left-text-float .group-image { float: left; max-width: 40%; margin-right: 1em; margin-bottom: 0.5em; }

@media (max-width: 719px) { .paragraphs-item-image-left-text-float .group-image { max-width: 100%; float: none; text-align: center; }
  .paragraphs-item-image-left-text-float .group-image img { width: 100%; max-width: 550px; display: inline-block; } }

.paragraphs-item-image-left-text-float .field-image-caption { font-size: .9em; font-style: italic; }

.paragraphs-item-quote { position: relative; padding: 1.25em 2em; }

@media (min-width: 720px) { .paragraphs-item-quote { margin-left: 9em; margin-right: 9em; } }

.paragraphs-item-quote .field-quote-text { font-style: italic; font-size: 1.1em; }

.paragraphs-item-quote .field-quote-text:before, .paragraphs-item-quote .field-quote-text:after { font-family: FontAwesome; font-size: 1.2em; color: #f1840f; position: absolute; }

.paragraphs-item-quote .field-quote-text:before { content: "\f10e"; left: 0; }

.paragraphs-item-quote .field-quote-text:after { content: "\f10d"; right: 0; position: relative; bottom: -5px; }

.paragraphs-item-quote .field-quote-author { margin-top: 1em; text-align: right; text-transform: uppercase; color: #f1840f; font-family: "Noto Sans", sans-serif; font-weight: 700; }

/*------------------------------------*  $TEASER
\*------------------------------------*/
@media (min-width: 651px) and (max-width: 950px) { .cmp--teaser .container { max-width: 600px; padding: 0; } }

@media (min-width: 951px) and (max-width: 1258px) { .cmp--teaser .container { max-width: 906px; padding: 0; } }

/*------------------------------------*  $SOCIAL
\*------------------------------------*/
.cmp--social { padding: 2em 0; text-align: center; background: #7e8186; }

/*------------------------------------*  $MISC
\*------------------------------------*/
.cmp--misc { text-align: left; padding: 2em 0; }

.cmp--misc, .cmp--misc a, .cmp--misc h2, .cmp--misc h3, .cmp--misc h4, .cmp--misc h5 { margin-bottom: 0; }

.cmp--misc .region--misc1 { margin-bottom: 1em; }

/*------------------------------------*  $FOOTER
\*------------------------------------*/
.cmp--footer { padding: 1em 0; }

.region--footer { font-size: 0.85rem; }

.region--footer, .region--footer a, .region--footer a:visited { color: #ffffff; font-family: "Noto Sans", sans-serif; transition: color, .3s; }

.region--footer p { margin-bottom: 0; }

.messages.error { border-left: 5px solid #3A87AD; border-color: #b94a48; background: #f5e4e4; color: #b94a48; margin-bottom: 1em; padding: 1.25em; }

.page-user .tabs--primary { display: none; }

.edit-customer-profile-eu-vat-rc { display: none; }

#block--language { float: right; }

.view--users--page table { border: 1px solid #ccc; }

.view--users--page table thead { background-color: #f1eded; }

.view--users--page table tbody tr.even { background-color: #fbf6f6; }

.view--users--page table td { padding: 5px 15px; }

#block--9 .grid { display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }

.agb-link { display: none; }

.customer_profile_eu_vat_rc legend { display: none; }

.checkout-review .organisation-name { font-weight: normal; }

.checkout-review .field-commerce-vat-number h3 { font-weight: normal; }

.i18n-en .form__item--field-amic-member-und { display: none; }

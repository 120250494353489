#topnavigation {
    position: relative;
    background-color: $academy-orange;
    overflow: auto;
    line-height: 1em; 
    padding:1.2em 0;
    .login-link {
    	display: inline-block;
    	margin-left: 2em;
    	a {
    		color: #fff;
    	}
    } 
    #region--topnavigation {
    	width: 100%;
    }
	.afs-academy-top-menu {
		float: right;
		display: inline-block;
    &.left{float: none;}
        color: $font-base-inverted;
        ul.menu__list {
            padding: 0;
            display: flex;
            li {
                list-style: none; ; display: block; float:left; text-transform: uppercase; padding: 0 1em;
                &:not(:last-child) {border-right: 1px solid #fff;}
                &.shoppingcart {
                  overflow: hidden;
                    .not-logged-in & {display: none;}
                        a {
                            text-indent: -9999px;
                            width: 20px;
                            &::before {
                              content: "\f07a";
                              font-family: fontawesome;
                              /* add icon back in to view */
                              display: inline-block;
                              position:absolute;
                              text-indent: 9999px;
                            }
                        }
                    }
                a {
                    color: $font-base-inverted;
                    &.outgoing-link {text-transform: none;
                        &:after {
                        content: "\f08e";
                        display: inline-block;
                        position: relative;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        padding-left: 4px;
                        }
                    }
               }
            }
        }
       
       
	}
    
}

.block--user-menu {
    //margin-left: 2em;
    display: inline-block;
  li {
    margin: 0 1em 0 0;
    display: inline-block;
  }
  a {

  display: inline-block;
  text-transform: uppercase;
    color: white;
  }
}

 
/*------------------------------------*\
  Button
\*------------------------------------*/

.button {
  padding: 0.7em 1.3em;
  background: $academy-orange;
  color: $font-base-inverted;
  text-transform: uppercase;
}

/*------------------------------------*\
  Breadcrumbs
\*------------------------------------*/

.easy-breadcrumb {
	padding: 4em 0;
	color: $font-base-inverted; 
	a {
		color: $font-base-inverted; 	
	}
}
@if $cmp-ckeditor {

/*------------------------------------*\
  CKEDITOR
\*------------------------------------*/
/**
 * CONTENTS
 * BASE................
 */



/*------------------------------------*\
  $BASE
\*------------------------------------*/

.cke_show_borders {
  margin: $spacing-s;
}

} // endif
/*------------------------------------*\
  Typography
\*------------------------------------*/
/**
 * CONTENTS
 * VISIBILITY..........
 */

body {
	font-family: $font-b-family;
	color: $font-base;
	font-size: $font-base-size;

}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-a-family;
	color: $headlines;
	text-transform: none;
}

h1 { };
h2 { };
h3 { };
h4 { };
h5 { };
h6 { };

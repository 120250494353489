@import 'config';

/*------------------------------------*\
  STYLE
\*------------------------------------*/


/**
 * CONTENTS
 * TOOLS...............
 * HEADER..............
 * HERO................
 * CONTENT.............
 * TEASER..............
 * SOCIAL..............
 * NEWSLETTER..........
 */


/*------------------------------------*\
  $TOOLS
\*------------------------------------*/

select,
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  border: none;
  border-bottom: 2px solid #b2b2b2;
  width: 100%;
  background: #fff;
}

input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

.clearfix {
  @extend .u-cf;
}

.container--s {
  max-width: 57em;
}

.container--l {
  max-width: 86em;
}

.colorbox {
  &:focus {
    outline: none;
  }
}

.septem-menu {
  z-index: 101;
}

.intro {
  font-size: $font-size-l;
  p {
    margin-bottom: 1.5rem;
  }
}

.font-b-family {
  &,
  a {
    font-family: $font-b-family;
  }
}

.uppercase {
  text-transform: uppercase;
}


/**
 * Responsiv images
 */

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

// Google Map fix
.gmap img,
.field--type-geolocation-latlng img {
  max-width: none;
}

@media (max-width: $breakpoint-m-max) {
  .media {
    .media__img {
      float: none;
      margin: 0;
      margin-bottom: $spacing-m;
    }
  }
}

/**
 * Embedd Container
 */
.embed-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 90%;
  max-width: 100%;
  height: 0;
  height: auto;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; height: 100%;
  }
}

.embed-container--youtube {
  padding-bottom: 56.25%
}

/*------------------------------------*\
  $HEADER
\*------------------------------------*/

.cmp--header {
  border-bottom: 1px solid rgba(23, 23, 23, 0.26);
  .not-front & {
    border-bottom: 0;
  }
  @media (max-width: 1024px) {
    border-bottom: 0;
  }
}

.headroom {
  position: fixed;
  z-index: 100;
  width: 100%;
  transition: background 0.3s 0.3s;
  animation-duration: 0.3s;
  // background: $cs-a;
  &.headroom--not-top {
    background: rgba(#222, 0.4);
  }
}

.logo--main,
.region--navigation {
  display: inline-block;
  /* flex-box fallback */
}

@media (max-width: ($cmp-menu-mobile-breakpoint - 1)) {
  .logo--main {
    display: block;
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 2.5em;
    max-width: 300px;
    img {
      width: 100%;
    }
  }
  .menu__controls {
    text-align: right;
  }
}

.nav-bar {
  padding: 1em 0;
}

@media (max-width: $breakpoint-m) {
  .logo__link {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.logo--main__img {
  height: 34px;
  transition: width 0.3s 0.3s;
  @media (min-width: $breakpoint-m) {
    width: 300px;
    height:56px;
    max-width: none;
    position: relative;
  }
  @media (min-width: 1100px) {
    width: 360px;
    height: 67px;
  }
}

.region--navigation {
  width: 100%;
  /* flex-box fallback */
  vertical-align: middle;
  /*********************/
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headlines;
}

/*------------------------------------*\
  $HERO
\*------------------------------------*/

.cmp--hero {
  @media (min-width: 1024px) {
      //border-top: 106px solid $cs-a;
  }

  @media (max-width: $breakpoint-m-max) {
    display: none;
  }

  .flex-caption {
    position: absolute;
    z-index: 1;
    bottom: $spacing-xl;
    width: 100%;

    .inner {
      font-size: $font-size-l;
      max-width: 20em;
    }


  .flex-prev:hover,
  .flex-next:hover {
    opacity: 1;
  }

  .flex-direction-nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    i {
      color: #fff;
      font-size: 4em;
    }

    a {
      height: auto;
      top: auto;
      bottom: $spacing-l;
    }
  }
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    height: auto;
    padding: 2px 0;
    text-align: center;

    li,
    a {
      display: inline-block;
    }

    a {
      margin: 0 0.2em;
      width: 0.8em;
      height: 0.8em;
      background: $cs-b;
      border-radius: 100%;
      text-decoration: none;
      text-indent: -999em;
      cursor: pointer;
      line-height: 71%;
  }

  .flex-active {
    background: $cs-a;
  }
}
}

/*------------------------------------*\
  $CONTENT
\*------------------------------------*/



.cmp--content {
  margin-top: 8em;

  @media (max-width: 1024px) {
    margin-top: $spacing-xl;
  }
  padding-bottom: $spacing-l;
  .front & {
    margin-top: 0;
    padding-bottom: 10px;
        .field--node-page--title-field {
      display: none;
    }
  }
}


/**
 * Page
 */
.field--field-page-image {
  margin-bottom: 1.5em;
}

/**
 * News
 */
.field--field-news-date {
  position: relative;
  top: -0.75em;
}

.field--field-news-image {
  margin-bottom: 1.5em;
}

/**
 * Location
 */

.field--field-location-contact-form {
  margin-top: $spacing-m;
}


/*
 * Paragraphs
 */

.entity-paragraphs-item {

  p:last-child {
    margin-bottom: 0;
  }
}

.paragraphs-item-intro {
  font-size: 1.2em;
}


.field--paragraphs_item-header_image--field-paragraph-image,
.paragraphs-item-intro,
.field-paragraph-textarea,
.paragraphs-item-50-50 img,
.paragraphs-item-50-50 .field--type-text-long,
.paragraphs-item-33-33-33 img,
.paragraphs-item-33-33-33 .field--type-text-long,
.paragraphs-item-gallery,
.paragraphs-item-image-left-text-float {
  margin-bottom: $spacing-m;
}

.paragraphs-item-image-left-text-float {
  .group-image {
    float: left;
    max-width: 40%;
    margin-right: $spacing-s;
    margin-bottom: $spacing-xs;
  }

   @media (max-width: $breakpoint-m-max) {
    .group-image {

      max-width: 100%;
      float: none;
      text-align: center;

      img {
        width: 100%;
        max-width: 550px;
        display: inline-block;
      }
    }
   }

  .field-image-caption {
    font-size: .9em;
    font-style: italic;
  }
}

.paragraphs-item-quote {
  position: relative;
  padding: $spacing-m $spacing-l;

  @media (min-width: $breakpoint-m) {
    margin-left: ($spacing-xl * 3);
    margin-right: ($spacing-xl * 3);
  }

  .field-quote-text {
    font-style: italic;
    font-size: 1.1em;

  &:before,
  &:after {
    font-family: FontAwesome;
    font-size: 1.2em;
    color: $cs-a;
    position: absolute;
  }

  &:before {
    content: "\f10e";
    left: 0;
  }

  &:after {
    content: "\f10d";
    right: 0;
    position: relative;
    bottom: -5px;
  }

  }

  .field-quote-author {
      margin-top: 1em;
      text-align: right;
      text-transform: uppercase;
      color: $cs-b;
      font-family: $font-b-family;
      font-weight: $font-b-weight-bold;
    }
}


/*------------------------------------*\
  $TEASER
\*------------------------------------*/


.cmp--teaser {
  @media  (min-width: 651px) and (max-width: 950px) {
    .container {
      max-width: 600px;
      padding: 0;
    }
  }
  @media  (min-width: 951px) and (max-width: 1258px) {
    .container {
      max-width: 906px;
      padding: 0;
    }
  }
}



/*------------------------------------*\
  $SOCIAL
\*------------------------------------*/

.cmp--social {
  padding: $spacing-l 0;
  text-align: center;
  background: $cs-c;
}




/*------------------------------------*\
  $MISC
\*------------------------------------*/




.cmp--misc {
  text-align: left;
  padding: $spacing-l 0;


  &,
  a,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }

  .region--misc1 {
    margin-bottom: 1em;
  }
}

/*------------------------------------*\
  $FOOTER
\*------------------------------------*/

.cmp--footer {
  padding: $spacing-s 0;
}

.region--footer {
  font-size: $font-size-s;
  &,
  a,
  a:visited {
    color: $font-base-inverted;
    font-family: $font-b-family;
    transition: color, .3s;
  }
  p {
    margin-bottom: 0;
  }
}


.messages.error {
  border-left: 5px solid #3A87AD;
  border-color: #b94a48;
background: #f5e4e4;
color: #b94a48;
margin-bottom: 1em;
padding: 1.25em;


}
.page-user {
  .tabs--primary {
  display: none;
  } 
}

.edit-customer-profile-eu-vat-rc {
  display: none;
}

//temp

#block--language {float:right;}

.agb-link {
  // display: none;
 }

 // Users table

.view--users--page {
  table {
    border: 1px solid #ccc;
    thead {
      background-color: #f1eded;
    }
    tbody tr.even {
      background-color: #fbf6f6;
    }
    td {
      padding: 5px 15px;
    }
  }
}

// Visa / Mastercard Checkout
#block--9 {
  .grid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
  }
}
.agb-link {
  display: none;
}

.customer_profile_eu_vat_rc {
	legend { 
	display: none; }
}

.checkout-review {
	.organisation-name {
		font-weight: normal;
	}
	.field-commerce-vat-number h3 {
		font-weight: normal;
	}
}

.i18n-en .form__item--field-amic-member-und {
	display: none;
}
/*------------------------------------*\
  LAYOUT
\*------------------------------------*/
/**
 * CONTENTS
 * CONTAINER...........
 * BOX-SIZING-RESET....
 */



/*------------------------------------*\
  $CONTAINER
\*------------------------------------*/

.container {
  margin: 0 auto;
  padding: 0 1em;
  max-width: $layout-max-width;
}



/*------------------------------------*\
  $BOX-SIZING-RESET
\*------------------------------------*/

@if $layout-box-sizing-reset {
  html {
    box-sizing: border-box;
  }
  * {
    &,
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }
}